<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <ArtistsMenu :eventId="eventId" active="general-information" :event="event" v-if="event">
      <div v-if="event" class="d-flex flex-row mt-3">
        <div class="flex-grow-1">
          <Breadcrumbs :event="event" currentPageName="General information"/>
        </div>
      </div>
      <h2 class="mb-5">General information</h2>
      <p>
        General information:
        <span v-if="event.general_information_published">published</span>
        <span v-else>not published</span>
      </p>
      <div class="h-75" v-if="event && editing">
        <vue-editor
          id="editor"
          :editor-toolbar="editorOptions"
          v-model="general_information"
        ></vue-editor>
        <div class="mt-2 clearfix d-flex justify-content-center">
          <Button :loading="loading" @click="saveGeneralInformation()">Save</Button>
          <Button :loading="loading" @click="saveAndPublish()">Save and publish</Button>
          <button class="btn btn-secondary" @click="editing=false">Cancel</button>
        </div>
      </div>
      <div v-else-if="event && !editing">
        <div v-html="general_information"></div>
        <Button @click="editing = true">Edit general information</Button>
      </div>
    </ArtistsMenu>
  </div>
</template>
<script>
import Navbar from '@/components/common/Navbar.vue'
import Breadcrumbs from "@/components/Breadcrumbs";
import ArtistsMenu from "@/components/ArtistsMenu";
import {VueEditor} from "vue2-editor";
import {getEventGeneralInformation, saveEventGeneralInformation} from "@/artists_api";
import Button from "@/components/common/Button.vue";
import store from "@/store";

export default {
  name: 'ArtistsGeneralInformation',
  components: {
    ArtistsMenu, Button,
    Navbar, Breadcrumbs, VueEditor
  },
  props: ['eventId'],
  data() {
    return {
      editor: null,
      general_information: null,
      event: null,
      loading: false,
      editing: false,
      editorOptions: [
        [{ "font": [] }, { "size": ["small", false, "large", "huge"] }], // custom dropdown
        ["bold", "italic", "underline", "strike"],
        [{ "color": [] }, { "background": [] }],
        [{ "script": "sub" }, { "script": "super" }],
        [{ header: [false, 1, 2, 3, 4] }, "blockquote", "code-block"],
        [{ "list": "ordered" }, { "list": "bullet" }, { "indent": "-1" }, { "indent": "+1" }],
        [{ "direction": "rtl" }, { "align": [] }],
        ["link"],
        ["clean"]
      ]
    }
  },
  methods: {
    saveGeneralInformation() {
      this.loading = true
      saveEventGeneralInformation(this.eventId, {'general_information': this.general_information})
        .finally(() => {
          this.loading = false
          this.editing = false
          store.addAlert("success", "Saved")
        })
    },
    saveAndPublish() {
      this.loading = true
      saveEventGeneralInformation(this.eventId,
        {
          'general_information': this.general_information,
          'publish': true
        })
        .finally(() => {
          this.loading = false
          this.editing = false
          this.event.general_information_published = true
          store.addAlert("success", "Published")
        })
    }
  },
  async mounted() {
    await getEventGeneralInformation(this.eventId).then(result => {
      this.event = result.event
      this.general_information = result.general_information
    })
  }
}
</script>
