<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <ArtistsMenu :eventId="eventId" active="artist" :event="event" v-if="event">

      <div class="d-flex align-items-center justify-content-between">
      <h1 class="">Artists</h1>
        <span class="">Artists: {{artists.length}}</span>
      </div>

      <div class="d-flex justify-content-between">
        <input type="text" v-model="filterArtistName" placeholder="Search artist"/>
        <router-link :to="{name: 'AddArtist' }" class="text-underline-none text-light">
          <button type="button" class="btn btn-primary">Add artist</button>
        </router-link>
      </div>

      <div class="card card-body mt-3" v-for="artist in selectedArtists" v-bind:key="artist.id">
        <div class="row">
          <div class="col-xl-3">
            <div class="item-title mb-2">
              Artist <span v-for="tag in artist.tags" v-bind:key="'at-'+artist.id+ '-'+tag.id"
                           class="badge rounded-pill bg-primary">{{ tag.name }}</span>
              <span class="ms-2" v-if="artist.mail_sent">
                <Icon name="envelope-check" width="15" height="15"></Icon>
              </span>
            </div>
            <div>
              {{ artist.name }}
            </div>
          </div>
          <div class="col-xl-1">
            <div class="row">
              <div class="col-md-3 col-lg-6 col-xl-12">
                <div class="item-title mb-2">
                  <Icon name="location" width="15" height="15"/>
                </div>
              </div>
              <div class="col col-xl-12">
                <div class="">
                  <span class="text-small fw-bold">{{ artist.country_code }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-1">
            <div class="item-title mb-2">
              Day
            </div>
            <div class="row">
              <div class="col-auto text-small fw-bold" v-if="artist.schedules.length > 0">
                <div v-for="s in artist.schedules" v-bind:key="'schedule-day'+s.id">
                  {{s.date | formatDateShort}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2">
            <div class="item-title mb-2">
              Time
            </div>
            <div class="row">
              <div class="col-auto  text-small fw-bold">
                <div v-if="artist.schedules.length > 0">
                  <div v-for="s in artist.schedules" v-bind:key="'schedule-time'+s.id">
                    {{s.start_time}}<span v-if="s.end_time != null"> - {{ s.end_time }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2">
            <div class="row">
              <div class="col-md-3 col-lg-6 col-xl-12">
                <div class="item-title mb-2">
                  Stage
                </div>
              </div>
              <div class="col col-xl-12">
                <div class="">
                <div class="text-small fw-bold">
                  <div v-for="s in artist.schedules" v-bind:key="'schedule-stage'+s.id">{{s.stage_name}}</div>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="item-title mb-2">
              Progress
            </div>
            <div class="row">
              <div class="col-auto">
                {{ artist.progress * 100 | round100 }}%
              </div>
            </div>
          </div>
          <div class="col-1">
            <div class="item-title mb-2">
              <router-link :to="{name: 'Artist', params: { eventId: eventId, artistId: artist.id }}" href="#">
                <button class="btn btn-outline-primary">View</button>
              </router-link>
            </div>
            <div class="d-flex flex-row">
              <router-link :to="{name: 'EditArtist', params: { eventId: eventId, artistId: artist.id }}" href="#">
                <button class="btn me-2 btn-outline-primary">Edit</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </ArtistsMenu>
  </div>
</template>
<script>
import Navbar from '@/components/common/Navbar.vue'
import {getEventArtists} from "@/artists_api";
import {getEvent, getEventData} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import ArtistsMenu from "@/components/ArtistsMenu";
import Icon from "@/components/common/Icon";

export default {
  name: 'Artists',
  components: {
    ArtistsMenu,
    Navbar,
    Icon
  },
  props: ['eventId'],
  data() {
    return {
      artists: [],
      filterArtistName: '',
      event: null
    }
  },
  methods: {
    filterArtist(p) {
      if (p.name && p.name.toLowerCase().includes(this.filterArtistName.toLowerCase())) {
        return true
      }

      if (p.name.toLowerCase().includes(this.filterArtistName.toLowerCase())) {
        return true
      } else {
        return false
      }

      return false
    },
  },
  async mounted() {
    this.artists = await getEventArtists(this.eventId)
    this.event = await getEventData(this.eventId)
  },
  computed: {
    selectedArtists: function () {
      return this.artists.filter(p => {
        return this.filterArtist(p)
      })
    },
  },
}
</script>
