<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <ArtistsMenu :eventId="eventId" active="accomodation" :event="event" v-if="event">
      <div v-if="event" class="d-flex flex-row mt-3">
        <div class="flex-grow-1">
          <Breadcrumbs :event="event" currentPageName="Accomodation"/>
        </div>
      </div>

      <div class="d-flex justify-content-between mb-5">
        <h2>Accommodation</h2>
        <div>
          <button class="btn btn-primary">
            <router-link class="text-light text-decoration-none" :to="{name: 'AddHotel'}">
              Add hotel
            </router-link>
          </button>
        </div>
      </div>

      <div v-for="hotel in hotels" v-bind:key="'hotel-' + hotel.id" class="card card-body mb-3">
        <div class="row">
          <div class="col item-title">
            Hotel
          </div>
          <div class="col item-title">
            Address
          </div>
          <div class="col item-title">
            Check-in
          </div>
          <div class="col item-title">
            Check-out
          </div>
        </div>
        <div class="row">
          <div class="col">
            {{ hotel.name }}
          </div>
          <div class="col">
            {{ hotel.address }}
          </div>

          <div class="col">
            {{ hotel.check_in }}
          </div>
          <div class="col">
            {{ hotel.check_out }}
          </div>
        </div>
        <div class="row mt-3">
          <div class="col item-title">
            Breakfast
          </div>
          <div class="col"></div>
        </div>
        <div class="row">
          <div class="col-9">
            {{ hotel.breakfast }}
          </div>
          <div class="col-3">
            <button class="btn btn-primary">
              <router-link class="text-light text-decoration-none" :to="{name: 'EditHotel', params: {hotelId: hotel.id}}" >
                Edit
              </router-link>
            </button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col item-title">
            Additional information
          </div>
          <div class="col"></div>
        </div>
        <div class="row">
          <div class="" v-html="hotel.additional_information"></div>
        </div>
      </div>

      <div class="mt-3 d-flex justify-content-center">
        <button v-if="event.accomodation_published" @click="unpublish" class="btn btn-primary">Unpublish
          accomodation
        </button>
        <button v-else class="btn btn-primary" @click="publish">Publish accomodation</button>
      </div>
    </ArtistsMenu>
  </div>
</template>
<script>
import Navbar from '@/components/common/Navbar.vue'
import {
  getEventArtists,
  getEventHotels,
  publishAccomodation,
  unpublishAccomodation
} from "@/artists_api";
import {getEventData} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import ArtistsMenu from "@/components/ArtistsMenu";
import store from "@/store";

export default {
  name: 'ArtistsAccomodation',
  components: {
    ArtistsMenu, Navbar, Breadcrumbs

  },
  props: ['eventId'],
  data() {
    return {
      artists: [],
      event: null,
      hotels: [],
      editedArtistId: null
    }
  },
  async mounted() {
    this.artists = await getEventArtists(this.eventId)
    this.event = await getEventData(this.eventId)
    this.hotels = await getEventHotels(this.eventId)
  },
  methods: {
    editArtist(artistId) {
      this.editedArtistId = artistId
    },
    publish() {
      publishAccomodation(this.eventId).then(() => {
        this.event.accomodation_published = true
        store.addAlert("success", "Accomodation published")
      })
    },
    unpublish() {
      unpublishAccomodation(this.eventId).then(() => {
        this.event.accomodation_published = false
        store.addAlert("success", "Accomodation unpublished")
      })
    }
  },
}
</script>
