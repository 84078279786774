<template>
  <div>
    <ArtistNavbar currentPage="Artists"/>

    <ArtistPageMenu v-if="event" :event="event" currentPage="GeneralInformation" :artist="artist">
      <h1>General information</h1>

      <div v-if="event.general_information_published" class="w-100 mt-5">
        <div class="" v-html="general_information"></div>
      </div>
      <div v-else class="d-flex justify-content-center w-100 mt-5">
        <h4 class="text-uppercase mt-5">Informative content coming soon, stay tuned!</h4>
      </div>
    </ArtistPageMenu>
  </div>
</template>
<script>
import ArtistNavbar from '@/components/ArtistNavbar.vue'
import ArtistPageMenu from "@/components/ArtistPageMenu";
import store from "@/store";
import {getArtistPage} from "@/artists_api";

export default {
  name: 'ArtistPageGeneralInformation',
  components: {ArtistPageMenu, ArtistNavbar},
  props: [],
  data() {
    return {
      artist: null,
      event: null,
      form: null,
      general_information: null
    }
  },
  async mounted() {
    this.artistId = store.getArtist()
    await getArtistPage(this.artistId).then(result => {
      this.artist = result.artist
      this.event = result.event
      this.eventId = this.event.id
      this.form = result.artist.form
      this.general_information = result.artist.general_information
    })
  }
}
</script>
