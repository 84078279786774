<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <div v-if="artist">
      <ArtistsMenu :eventId="eventId" active="artist" :event="event" v-if="event">
        <h1>{{ artist.name }}</h1>
        <div v-if="form">
          <ArtistData :artist="artist" :eventId="eventId" :event="event" :form="form" :artistId="artistId"
                      :fromAdmin="true"/>
          <ArtistFiles :artist="artist" :form="form" :eventId="eventId"/>
          <ArtistTransfers v-if="'Transfers' in form.sections" :artist="artist" :eventId="eventId" :event="event" :artistId="artistId" :form="form" />
          <div v-if="!artist.form_submitted" class="mt-5 d-flex justify-content-center w-100">
            <button type="button" @click="saveAndSubmit()" class="ms-2 btn btn-primary">Save and submit</button>
          </div>
          <div v-else class="mt-2 d-flex justify-content-center w-100">
            <button type="button" @click="removeSaveAndSubmit()" class="ms-2 btn btn-primary">Set form not submitted
            </button>
          </div>
          <hr class="mb-5"/>
          <ArtistSchedule :artistId="artist.id" :event="event" :eventId="eventId" :fromAdmin="true"/>
          <div v-if="'Accommodation' in form.sections">
            <ArtistAccomodationItem :artist="artist" :eventId="eventId" :event="event"
                                    :hotels="hotels" v-on:editingArtist="editArtist"
                                    :editedArtist="editedArtistId" :editing="true"/>
          </div>
          <ArtistUsers :artist="artist" :eventId="eventId" :artistId="artistId" />
        </div>
        <h1 v-else="">No form selected</h1>
      </ArtistsMenu>
    </div>
  </div>
</template>
<script>
import Navbar from '@/components/common/Navbar.vue'
import {
  getEventArtis, getEventHotels, saveAndSubmitArtist, setArtistNotSubmitted
} from "@/artists_api";
import {getEventData} from "@/api";
import ArtistData from "@/components/ArtistData";
import ArtistsMenu from "@/components/ArtistsMenu";
import ArtistSchedule from "@/components/ArtistSchedule";
import ArtistAccomodationItem from "@/views/artists/ArtistAccomodationItem";
import ArtistTransfers from "@/components/ArtistTransfers";
import ArtistUsers from "@/views/artist/ArtistUsers.vue";
import ArtistFiles from "@/components/ArtistFiles.vue";


export default {
  name: 'Artist',
  components: {
    ArtistFiles,
    ArtistUsers, ArtistAccomodationItem, ArtistSchedule, ArtistsMenu, Navbar, ArtistData, ArtistTransfers},
  props: ['eventId', 'artistId'],
  data() {
    return {
      artist: null,
      event: null,
      form: null,
      editedArtistId: null,
      hotels: [],
    }
  },
  methods: {
    removeSaveAndSubmit() {
      setArtistNotSubmitted(this.eventId, this.artistId).then(() => {
        this.artist.form_submitted = false
        store.addAlert("success", "Artist form set to not submitted")
      })
    },
    saveAndSubmit() {
      if (window.confirm("After submitting the form you can't make any more changes, are you sure?")) {
        saveAndSubmitArtist(this.eventId, this.artistId)
        this.artist.form_submitted = true
      }
    },
    editArtist(artistId) {
      this.editedArtistId = artistId
    },
  },
  async mounted() {
    this.artist = await getEventArtis(this.eventId, this.artistId)
    this.event = await getEventData(this.eventId)
    this.hotels = await getEventHotels(this.eventId)
    if (this.artist.advancing_form_id != null) {
      this.form = this.artist.form
    } else {
      this.form = null
    }
  }
}
</script>
