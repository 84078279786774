<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="EventUsers"></Navbar>
    <div class="container-fluid">
      <Breadcrumbs :event="event" currentPageName="Event users"/>
      <h1>Event users</h1>
      <div class="card elevation-16 mb-3">
        <div class="card-body">
          <form v-on:submit.prevent="addUser">
            <div class="row">
              <div class="col-12 col-md-6">
                <label class="form-label">E-mail
                  <input minlength="6" class="form-control" v-model="email" type="text"/>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <label class="form-label">Name
                  <input class="form-control" v-model="name" type="text"/>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <label class="form-label">Role
                  <select v-model="role" class="form-control">
                    <option value="null" disabled>Select role</option>
                    <option value="worker_bee">Worker bee</option>
                    <option value="worker_bee+">Worker bee+</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <label class="form-label">Password</label>
                <input type="password" v-model="password" class="form-control"/>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 col-md-6">
                <label class="form-label">Enter password again</label>
                <input type="password" v-model="password2" class="form-control"/>
              </div>
            </div>
            <div class="mt-2">
              <button class="btn btn-primary" type="submit">Add user</button>
            </div>
          </form>
        </div>
      </div>
      <div class="card mb-2" v-for="user in users" v-bind:key="user.id">
        <div class="card-body">
          <div class="row" v-if="editingUser && editingUser === user">
            <div class="col-3">
              <div class="item-title">Name</div>
              <div><input type="text" v-model="editingUserName" class="form-control"/></div>
            </div>
            <div class="col-3">
              <div class="item-title">E-mail</div>
              <div><input type="text" v-model="editingUserEmail" class="form-control"/></div>
            </div>
            <div class="col-3">
              <div class="item-title">Role</div>
              <div><select v-model="editingUserRole" class="form-control">
                <option :value="null" disabled>Select role</option>
                <option value="worker_bee">Worker bee</option>
                <option value="worker_bee+">Worker bee+</option>
              </select></div>
            </div>
            <div class="col-3">
              <div class="item-title">&nbsp;</div>
              <div>
                <button class="btn btn-primary me-2" @click="saveEditingUser()">
                  <Icon name="check" width="15" height="15"/>
                </button>
                <button class="btn btn-primary" @click="cancelEditingUser()">
                  <Icon name="close" width="15" height="15"/>
                </button>
              </div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-3">
              <div class="item-title">Name</div>
              <div>{{ user.name }}</div>
            </div>
            <div class="col-3">
              <div class="item-title">E-mail</div>
              <div>{{ user.email }}</div>
            </div>
            <div class="col-3">
              <div class="item-title">Role</div>
              <div>{{ roles[user.role] }}</div>
            </div>
            <div class="col-3">
              <div class="item-title">&nbsp;</div>
              <div>
                <button class="btn btn-primary me-2" @click="removeUser(user.id)">
                  <Icon name="trash" width="15" height="15"/>
                </button>
                <button class="btn btn-primary" @click="editUser(user)">
                  <Icon name="edit" width="15" height="15"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Navbar from '@/components/common/Navbar.vue'
import store from "@/store";
import {addEventUser, getEvent, getEventUsers, removeEventUser, updateEventUser} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import Icon from "@/components/common/Icon";

export default {
  name: 'AddUser',
  components: {Breadcrumbs, Navbar, Icon},
  props: ['eventId'],
  data() {
    return {
      roles: {'worker_bee': 'Worker bee', 'worker_bee+': 'Worker bee+'},
      email: '',
      name: '',
      role: null,
      event: null,
      users: [],
      password: '',
      password2: '',
      editingUser: null,
      editingUserRole: null,
      editingUserName: null,
      editingUserEmail: null
    }
  },
  methods: {
    addUser() {
      if (this.password !== this.password2) {
        alert('Passwords do not match')
      } else {
        addEventUser(this.eventId, {
          email: this.email, role: this.role, name: this.name, password: this.password
        }).then((result) => {
          store.addAlert("success", "User added")
          this.name = ''
          this.email = ''
          this.password = ''
          this.password2 = ''
          this.role = null
          this.users.push(result)
        })
      }
    },
    removeUser(userId) {
      removeEventUser(this.eventId, userId).then(() => {
        store.addAlert("success", "User removed")
        this.users = this.users.filter((u) => u.id !== userId)
      })
    },
    editUser(user) {
      this.editingUser = user
      this.editingUserRole = user.role
      this.editingUserName = user.name
      this.editingUserEmail = user.email
    },
    cancelEditingUser() {
      this.editingUser = null
      this.editingUserRole = null
      this.editingUserName = null
      this.editingUserEmail = null
    },
    saveEditingUser() {
      updateEventUser(this.eventId, this.editingUser.id, {
        'name': this.editingUserName, 'role': this.editingUserRole, 'e_mail': this.editingUserEmail
      }).then(result => {
        store.addAlert("success", "User updated")
        this.editingUser.name = this.editingUserName
        this.editingUser.email = this.editingUserEmail
        this.editingUser.role = this.editingUserRole
        this.cancelEditingUser()
      })
    }
  },
  async mounted() {
    this.users = await getEventUsers(this.eventId)
    const result = await getEvent(this.eventId)
    this.event = result.event
  }
}
</script>
