<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="People"/>
    <div id="" class="container-fluid" v-if="unit">
      <Modal v-bind:show-modal.sync="changingUnitName" :modal-class="'modal-full-screen modal-dialog-centered'">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-content overflow-auto">
              <div class="modal-header d-flex justify-content-between">
                <h5 class="modal-title">Edit unit name</h5>
                <button type="button" @click="deleteUnit" class="btn btn-primary">Delete unit</button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-auto">
                    <div class="item-title">
                      Name
                    </div>
                    <div>
                      <div class="row">
                        <div class="col-auto">
                          <input type="text" class="form-control pe-0" v-model="unitName"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-secondary"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="close()">
                  Cancel
                </button>
                <button type="button" class="btn btn-primary" @click="saveUnitName">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal v-bind:show-modal.sync="addingOnePerson" :modal-class="'modal-full-screen modal-dialog-centered'">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-content overflow-auto">
              <div class="modal-header">
                <h5 class="modal-title">Add new person</h5>
              </div>
              <div class="modal-body">
                <div v-if="personExists" class="text-danger">
                  Person with such a name already exists, do you still want to add them?
                </div>
                <div class="row mt-3">
                  <div class="col-auto">
                    <div class="item-title mb-2">
                      Name
                    </div>
                    <div>
                      <div class="row">
                        <div class="col-auto">
                          <input type="text" class="form-control pe-0" v-model="personName"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-auto">
                    <div class="item-title mb-2">
                      Group
                    </div>
                    <div>
                      <div class="row">
                        <div class="col-auto">
                          <select required class="form-select" v-model="personGroupId" aria-label="Select unit">
                            <option :value="null">No group</option>
                            <option v-for="group in groups" :value="group.id"
                                    v-bind:key="'group-' + group.id">{{ group.name }}
                            </option>
                          </select>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-auto">
                    <div class="item-title mb-2">
                      Comments
                    </div>
                    <div>
                      <div class="row">
                        <div class="col-auto">
                          <input type="text" class="form-control pe-0" v-model="personComments"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-auto">
                    <div class="item-title mb-2">
                      Extra
                    </div>
                    <div>
                      <div class="row">
                        <div class="col-auto">
                          <input type="text" class="form-control pe-0" v-model="personExtra"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="cancelAddNewPerson">
                  Cancel
                </button>
                <button v-if="!personExists" type="button" class="btn btn-primary" @click="checkIfExistsAndAdd">Add new person</button>
                <button v-if="personExists" type="button" class="btn btn-primary" @click="addPerson">Add person again</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal v-bind:show-modal.sync="multiAddOpened" :modal-class="'modal-lg modal-full-screen modal-dialog-centered'">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-content overflow-auto">
              <div class="modal-header">
                <h5 class="modal-title">Multi-add to unit {{unit.name}}</h5>
                <div class="col-auto p-0">
                </div>
              </div>
              <div class="modal-body" v-if="multiAddStep === 1">
                <textarea class="form-control w-100" rows="10" v-model="multiAddPeopleAsText"></textarea>
                <p>Add each new entry onto a new row. The entry format is:<br/>Jane Jones +3, comment : Unit : Group</p>
              </div>
              <div class="modal-body" v-if="multiAddStep === 2">
                <div class="row">
                  <div class="col">
                    Select group:
                    <select class="form-select" v-model="multiAddGroupId" aria-label="Select unit">
                      <option :value="null">No group</option>
                      <option v-for="group in groups" :value="group.id"
                              v-bind:key="'group-' + group.id">{{ group.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <table class="table">
                  <thead>
                  <tr>
                    <th>Name</th>
                    <th>Comments</th>
                    <th>Extra</th>
                    <th>Group</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="guest in multiAddGuestsList" v-bind:key="'guest-' + guest.id">
                    <td>
                      <div class="d-flex">
                        <input type="text" class="form-control" v-model="guest.name"/>
                        <span v-if="guest.exists" class="ms-2 text-danger">!!</span>
                      </div>
                    </td>
                    <td><input type="text" class="form-control" v-model="guest.comments"/></td>
                    <td><input type="text" class="form-control" style="width: 3em" v-model="guest.guests"/></td>
                    <td>
                      <select class="form-select" v-model="guest.group_id" aria-label="Select unit">
                        <option :value="null">No group</option>
                        <option v-for="group in groups" :value="group.id"
                                v-bind:key="'group-'+group.id">{{ group.name }}
                        </option>
                      </select>
                    </td>
                    <td><input type="checkbox" v-model="guest.selected"/></td>
                  </tr>
                  </tbody>
                </table>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-primary" @click="removeSelectedPeople">Remove</button>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button type="submit" class="btn btn-primary"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="cancelMultiAdd()">
                  Cancel
                </button>
                <button v-if="multiAddStep === 2" class="btn btn-primary me-2" @click="saveAllPeople">Save</button>
                <button v-if="multiAddStep === 1" type="button" class="btn btn-primary" @click="continueMultiAdd">Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div v-if="event && unit" class="d-flex flex-row mt-3">
        <div class="flex-grow-1">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Event', params: { id: event.id }}" class="text-disabled text-decoration-none">
                  {{ event.name }}
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name: 'EventPeople', params: { id: event.id }}"
                             class="text-disabled text-decoration-none">
                  People
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">{{ unit.name }}</li>
            </ol>
          </nav>
        </div>
      </div>
      <div v-if="event && unit">
        <div class="d-flex justify-content-start">
          <h3 class="mb-5">Unit: {{ unit.name }}</h3>
          <div class="ms-2">
            <button v-if="!editingUnit" @click="changeUnitName" class="me-2 btn btn-primary"><Icon width="15" height="15" name="edit" /></button>
          </div>
          <div class="flex-grow-1"></div>
          <div class="justify-content-end">
            <div class="d-flex align-content-end">
            <select class="form-select me-2" v-model="unit.profile_id">
              <option :value="null">Set profile</option>
              <option v-for="profile in profiles" :value="profile.id" v-bind:key="profile.id">{{ profile.name }}
              </option>
            </select>
            <button @click="updateUnitProfile(unit)" class="btn btn-primary">Set&nbsp;unit&nbsp;profile</button>
            </div>
          </div>
        </div>
        <button @click="openAddNewPerson" class="mt-2 mb-3 btn btn-primary me-2">Add new person</button>
        <button @click="openMultiAdd" class="mt-2 mb-3 btn btn-primary">Multi-add</button>

        <div v-if="!isOnlyGuestlist" class="mt-3">
          <hr />
          <h3 class="mt-5 mb-3">Groups</h3>
            <div class="row">
              <div class="col-8">
                <div class="item-title">
                  Add group
                </div>
                <div class="row">
                  <div class="col-auto">
                    <input type="text" class="form-control pe-0" v-model="groupName"/>
                  </div>
                  <div class="col-auto p-0">
                    <button class="btn btn-primary btn-small" @click="addGroup">Add group</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-6">
                <div class="list-group-item"
                     v-for="group in groups" v-bind:key="group.id">
                  <div class="row mb-1" style="border: 1px solid; border-radius: 3px">
                    <span style="cursor: pointer" @click="editGroup(group)" class="col-7">{{ group.name }}</span>
                    <span class="col-5 mh-100 flex-shrink-1 d-flex justify-content-end align-items-center">
                      <span @click="editGroup(group)"><Icon name="edit" class="m-2"/></span>
                      <span @click="removeGroup(unitId, group.id)"><Icon name="trash"/></span>
                      <span v-if="selectedGroup === group.id" @click="selectGroup(group.id)">
                        <Icon name="shrink" class="m-2"/>
                      </span>
                      <span v-else @click="selectGroup(group.id)"><Icon name="expand" class="m-2"/></span>
                    </span>
                  </div>
                  <div v-if="selectedGroup === group.id" class="row my-2">
                    <div class="col-auto">
                      <select class="form-select" v-model="group.profile_id">
                        <option :value="null">Set profile</option>
                        <option v-for="profile in profiles" :value="profile.id" v-bind:key="profile.id">{{profile.name}}</option>
                      </select>
                    </div>
                    <div class="col-auto">
                      <button @click="updateGroupProfile(group)" class="btn btn-primary">Set profile</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <hr class="mb-5"/>

        <PeopleData :people="people" :event="event" :selectedUnit="parseInt(unitId)" :selectedGroup="selectedGroup"
                    :unit_names="unit_names"
                    :units="units"
                    :group_names="group_names" :profiles="profiles"
                    :unitId="unitId"
                    :onlyGuestlist="isOnlyGuestlist"
                    v-on:addedPeople="addedPeople"
                    v-on:updatedProfile="updatedProfile"
                    v-on:updatedPerson="updatedPerson"
        />
      </div>
    </div>
  </div>
</template>
<script>
import store from '../store'
import Navbar from '@/components/common/Navbar.vue'
import {
  addEventGroup, addGroupPerson, addMultipleGuests, addUnitPerson, deleteGroup,
  deleteUnit, eventCheckIfGuestExists, eventCheckIfGuestsExist,
  eventUnitAddManager,
  getEvent,
  getEventUnit, updateGroup,
  updateUnit
} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import PeopleData from "@/components/PeopleData";
import Modal from "@/components/common/Modal.vue";
import Icon from "@/components/common/Icon";
import router from "@/router";

export default {
  name: 'EventUnit',
  props: ['eventId', 'unitId'],
  components: {
    Navbar,
    PeopleData,
    Icon,
    Modal
  },
  data() {
    return {
      editingUnit: false,
      unit: null,
      units: [],
      isOnlyGuestlist: store.isOnlyGuestlist(),
      changingUnitName: false,
      unitName: '',
      formFields: [],
      event: null,
      groupName: '',
      groups: [],
      managerEMail: '',
      selectedGroup: null,
      people: [],
      addPersonFields: [],
      group_names: [],
      unit_names: [],
      wristbands: [],
      badges: [],
      profiles: [],
      loaded: false,

      personExists: false,
      addingOnePerson: false,
      personName: '',
      personComments: '',
      personExtra: 0,
      personUnitId: '',
      personGroupId: '',

      multiAddPeopleAsText: '',
      multiAddOpened: false,
      newPersonAddOpened: false,
      multiAddStep: null,
      multiAddGuestsList: [],
      multiAddGroupId: null,
    }
  },
  methods: {
    cancelAddNewPerson() {
      this.addingOnePerson = false
      this.personComments = ''
      this.personName = ''
      this.personExtra = 0
      this.personGroupId = null
      this.personExists = false
    },
    cancelMultiAdd() {
      this.multiAddOpened = false
      this.multiAddPeopleAsText = ''
      this.multiAddGuestsList = []
    },
    continueMultiAdd() {
      eventCheckIfGuestsExist(this.eventId, this.multiAddPeopleAsText).then((result) => {
        this.multiAddStep = 2
        this.multiAddGuestsList = result
      })
    },
    async saveAllPeople() {
      this.multiAddGuestsList.forEach(g => {
        if (g.group_id === null) {
          g.group_id = this.multiAddGroupId
        }
      })
     let result = await addMultipleGuests(this.eventId, this.multiAddGuestsList, this.unitId, null)
      this.people.push(...result)
      this.cancelMultiAdd()
    },
    removeSelectedPeople() {
      this.multiAddGuestsList = this.multiAddGuestsList.filter(g => g.selected !== true)
    },

    async addPerson() {
      if (this.personGroupId) {
        addGroupPerson(this.eventId, this.unitId, this.personGroupId, {
          name: this.personName,
          comments: this.personComments,
          extra: this.personExtra
        }).then(result => {
          this.people.push(result)
          this.cancelAddNewPerson()
        })
      } else {
        addUnitPerson(this.eventId, this.unitId, {
          name: this.personName,
          comments: this.personComments,
          extra: this.personExtra
        }).then(result => {
          this.people.push(result)
          this.cancelAddNewPerson()
        })
      }
    },
    async checkIfExistsAndAdd() {
      const exists = await eventCheckIfGuestExists(this.eventId, this.personName)
      if (exists.exists) {
        this.personExists = true
      } else {
        this.addPerson()
      }
    },


    openAddNewPerson() {
      this.addingOnePerson = true
    },
    openMultiAdd() {
      this.multiAddOpened = true
      this.multiAddStep = 1
    },
    addedPeople(added) {
      this.people = [...this.people, ...added]
    },
    updatedProfile(profileId, updatedPeople) {
      this.people.forEach(p => {
        if (updatedPeople.includes(p.id)) {
          p.profile_id = profileId
        }
      })
    },
    close() {
      this.changingUnitName = false
    },
    changeUnitName() {
      this.changingUnitName = true
      this.unitName = this.unit.name
    },
    async saveUnitName() {
      await updateUnit(this.eventId, this.unitId, {name: this.unitName})
      this.unit.name = this.unitName
      this.close()
    },
    async deleteUnit() {
      if (confirm("Are you sure you want to delete the unit?")) {
        await deleteUnit(this.eventId, this.unitId)
      }
    },
    updatedPerson(personId, data) {
      let person = this.people.find(p => p.id === personId)
      person.name = data.name
      person.comments = data.comments
      person.guest_count = data.guest_count
      person.blocked = data.blocked
      person.pending = data.pending
    },
    editGroup(group) {
      router.push({name: 'EventGroup', params: {eventId: this.event.id, unitId: group.unitId, groupId: group.id}})
    },
    async addGroup() {
      const group = await addEventGroup(this.eventId, this.unitId, {name: this.groupName})
      this.groups.push(group)
      this.groupName = ''
    },
    updateGroupProfile(group) {
      updateGroup(this.event.id, group.unitId, group.id, {profile_id: group.profile_id})
    },
    async addManager() {
      await eventUnitAddManager(this.eventId, this.unitId, {e_mail: this.managerEMail})
    },
    selectGroup(group) {
      if (this.selectedGroup === group) {
        this.selectedGroup = null
      } else {
        this.selectedGroup = group
      }
      this.$emit('selectedGroup', group)
    },
    updateUnitProfile(unit) {
      updateUnit(this.event.id, unit.id, {profileId: unit.profile_id}).then(() => {
        for (let person of this.people) {
          person.profile_id = unit.profile_id
        }
        store.addAlert("success", "Profile updated")
      })
    },
    getUnitGroups(unit_id) {
      let unit = this.units.find(u => u.id === unit_id)
      if (unit) {
        return unit.groups
      }
    },
    removeGroup(unitId, groupId) {
      if (confirm("Are you sure you want to remove this group?")) {
        deleteGroup(this.eventId, unitId, groupId)
        let unit = this.units.filter(u => u.id === unitId)
        if (unit.length === 1) {
          unit[0].groups = unit[0].groups.filter(g => g.id !== groupId)
        }
      }
    }
  },
  async mounted() {
    const result = await getEventUnit(this.eventId, this.unitId)
    this.groups = result.groups
    this.unit = result.unit
    this.people = result.people
    this.formFields = result.form_fields
    this.loaded = true

    const eventResult = await getEvent(this.eventId)
    this.event = eventResult.event
    this.units = eventResult.units
    this.group_names = eventResult.group_names
    this.unit_names = eventResult.unit_names
    this.wristbands = eventResult.wristbands
    this.badges = eventResult.badges
    this.profiles = eventResult.profiles
  }
}
</script>
