<template>
  <div class="container">
    <Navbar></Navbar>
    <div><h4>Events</h4>
      <div v-for="event in events" v-bind:key="event.id">
        <EventItem :event="event" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import store from '../store'
import Navbar from '@/components/common/Navbar.vue'
import EventItem from "@/components/EventItem";
import {getEvents, getUserDashboard} from "@/api";

export default {
  name: 'UserDashboard',
  components: {
    EventItem,
    Navbar
  },
  data () {
    return {
      events: [],
      groups: [],
      units: []
    }
  },
  async mounted() {
    const headers = {
      "Authorization": "Bearer " + store.jwt()
    }

    ///const result = await axios.get("/api/user_dashboard")
    let foo = await getEvents()

    let events = store.getEventUsers()
  }
}
</script>
