<template>
  <div>
    <ArtistNavbar :event="event" currentPage="Artists"/>

    <div v-if="artist && form && event">
      <ArtistPageMenu :event="event" currentPage="Form" :artist="artist">
        <h1>{{ artist.name }}</h1>
        <p v-if="artist.form_submitted">Your form has been submitted!</p>
        <ArtistData v-if="form" :artist="artist" :eventId="eventId" :event="event" :form="form" :artistId="artistId"/>
        <ArtistFiles :artist="artist" :form="form" :eventId="eventId"/>
        <ArtistTransfers v-if="'Transfers' in form.sections" :artist="artist" :eventId="eventId" :event="event"
                         :form="form" :artistId="artistId"/>
        <div v-if="!artist.form_submitted" class="mt-5 d-flex justify-content-center w-100">
          <button type="button" class="me-2 btn btn-secondary">Save</button>
          <button type="button" @click="saveAndSubmit()" class="ms-2 btn btn-primary">Save and submit</button>
        </div>
        <hr class="mb-5"/>
        <div v-if="'Accommodation' in form.sections">
          <ArtistAccomodationItem :artist="artist" :eventId="eventId" :event="event"
                                  :hotels="hotels" :editing="false"/>
        </div>
        <ArtistSchedule :artistId="artist.id" :eventId="eventId" :event="event"/>
      </ArtistPageMenu>
    </div>
  </div>
</template>
<script>
import ArtistNavbar from '@/components/ArtistNavbar.vue'
import {
  getArtistPage, getEventHotels, saveAndSubmitArtist,
} from "@/artists_api";
import store from "@/store";
import ArtistPageMenu from "@/components/ArtistPageMenu";
import ArtistData from "@/components/ArtistData";
import ArtistSchedule from "@/components/ArtistSchedule";
import ArtistAccomodationItem from "@/views/artists/ArtistAccomodationItem";
import ArtistTransfers from "@/components/ArtistTransfers.vue";
import ArtistFiles from "@/components/ArtistFiles.vue";

export default {
  name: 'ArtistAdvancingForm',
  components: {
    ArtistFiles,
    ArtistTransfers, ArtistAccomodationItem, ArtistPageMenu, ArtistNavbar, ArtistData, ArtistSchedule
  },
  props: [],
  data() {
    return {
      artistId: null,
      artist: null,
      eventId: null,
      event: null,
      form: null,
      hotels: []
    }
  },
  async mounted() {
    this.artistId = store.getArtist()
    await getArtistPage(this.artistId).then(result => {
      this.artist = result.artist
      this.event = result.event
      this.eventId = this.event.id
      getEventHotels(this.eventId).then(result => {
        this.hotels = result
      })
      this.form = result.artist.form
    })
  },
  methods: {
    saveAndSubmit() {
      if (window.confirm("After submitting the form you can't make any more changes, are you sure?")) {
        saveAndSubmitArtist(this.eventId, this.artistId)
        this.artist.form_submitted = true
      }
    }
  },
}
</script>
