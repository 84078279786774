<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <ArtistsMenu :eventId="eventId" active="artist" :event="event" v-if="event">

      <h1>Add an artist</h1>
      <div class="card elevation-16 mb-3">
        <div class="card-body">
          <form v-on:submit.prevent="addArtist">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="item-title mb-2">
                    Name
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="text" class="mb-2 form-control pe-0" v-model="name"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="item-title mb-2">
                    Country code (e.g. EST, FIN)
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="text" class="mb-2 form-control pe-0" v-model="country_code"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="item-title mb-2">
                    Contact e-mail
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input type="email" class="mb-2 form-control pe-0" v-model="contactEmail"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <input type="submit" class="btn btn-primary" value="Add artist"/>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ArtistsMenu>
  </div>
</template>
<script>
import axios from 'axios'
import Navbar from '@/components/common/Navbar.vue'
import store from "@/store";
import {addArtist, getEventArtis} from "@/artists_api";
import {getEvent} from "@/api";
import ArtistsMenu from "@/components/ArtistsMenu";

export default {
  name: 'AddArtist',
  components: {Navbar, ArtistsMenu},
  props: ['eventId'],
  data() {
    return {
      event: null,
      name: '',
      contactEmail: '',
      country_code: ''
    }
  },
  async mounted() {
    this.event = await getEvent(this.eventId)
  },
  methods: {
    async addArtist() {
      await addArtist(this.eventId, {
        name: this.name, contactEmail: this.contactEmail, country_code: this.country_code
      })
      this.$router.replace({name: 'Artists'})
    }
  }
}
</script>
