<template>
  <div class="card mt-3">
    <div class="card-body">
      <div class="row">
      <span class="col-7">
        <router-link
          :to="{name: 'Event', params: { id: event.id }}"><strong>{{ event.name }}</strong></router-link></span>
        <span class="col-5 mh-100 flex-shrink-1 d-flex justify-content-end align-items-center">
          <span @click="editEvent()"><Icon name="edit" class="m-2"/></span>
          <span @click="deleteEvent()"><Icon name="trash"/></span>
          </span>
      </div>
    </div>
    <Modal v-bind:show-modal.sync="editingEvent" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Edit event</h5>
              <div class="col-auto p-0">

              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-auto">
                  <div class="item-title">
                    Name
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="eventName"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="closeEventEditing()">
                Close
              </button>
              <button type="button" class="btn btn-primary" @click="saveChanges()">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import Icon from "@/components/common/Icon";
import {removeEvent, saveEvent} from "@/api";
import Modal from "@/components/common/Modal.vue";

export default {
  name: 'EventItem',
  components: {Icon, Modal},
  data() {
    return {
      editingEvent: false,
      eventName: ''
    }
  },
  methods: {
    deleteEvent() {
      if (confirm("Are you sure you want to delete this event?")) {
        removeEvent(this.event.id).then(() => this.$router.go())
      }
    },
    saveChanges() {
      saveEvent(this.event.id, {name: this.eventName}).then(() => {
        this.editingEvent = false
        this.event.name = this.eventName
      })
    },
    editEvent() {
      this.eventName = this.event.name
      this.editingEvent = true
    },
    closeEventEditing() {
      this.editingEvent = false
    }
  },
  props: [
    'event'
  ]
}
</script>
