<script>
import Icon from "@/components/common/Icon.vue";
import dayjs from 'dayjs'
import {removeFile, saveFileLink, saveRiderLink} from "@/artists_api";
import store from "@/store";

export default {
  name: "ArtistFiles",
  components: {Icon},
  props: ["form", "artist", "eventId"],
  data() {
    return {
      filesAllowed: true,
      ridersAllowed: true,
      showUploadRider: false,
      linkToRider: '',
      linkToFile: '',
      uploadFieldName: '',
      uploadFieldNameFile: '',

      addFileLoading: false,
      addRiderLoading: false,
    }
  },
  mounted() {
    if (dayjs(this.artist.rider_deadline) < dayjs()) {
      this.ridersAllowed = false
    }
    if (dayjs(this.artist.other_files_deadline) < dayjs()) {
      this.filesAllowed = false
    }
  },
  methods: {
    async uploadRiderFile() {
      const formData = new FormData()
      console.log(this.$refs.file)
      formData.append('rider-file', this.$refs.file.files[0])
      formData.append('fileType', 'rider')
      const headers = {
        "Authorization": "Bearer " + store.jwt(),
        "Accept": "application/json"
      };
      this.addRiderLoading = true

      fetch(`/api/event/${this.eventId}/artists/${this.artist.id}/rider_files`, {
        method: 'POST',
        headers: headers,
        body: formData
      }).then((response) => response.json())
        .then((data) => {
          this.artist.rider_files.push(data)
        }).finally(() => this.addRiderLoading = false)
      store.addAlert("success", "Rider added", true)
      this.closeAddRider()
    },
    async uploadFile() {
      const formData = new FormData()
      console.log(this.$refs.other_file)
      formData.append('other-file', this.$refs.other_file.files[0])
      formData.append('fileType', 'rider')
      const headers = {
        "Authorization": "Bearer " + store.jwt(),
        "Accept": "application/json"
      };
      this.addFileLoading = true
      fetch(`/api/event/${this.eventId}/artists/${this.artist.id}/files`, {
        method: 'POST',
        headers: headers,
        body: formData
      }).then((response) => response.json())
        .then((data) => {
          this.artist.files.push(data)
        })
        .finally(() => {
          this.addFileLoading = false
          store.addAlert("success", "File added", true)
        })

      this.closeAddRider()
    },
    async addRiderLink() {
      let result = await saveRiderLink(this.eventId, this.artistId, {'url': this.linkToRider})
      this.linkToRider = ''
      this.artist.rider_files.push(result)
      store.addAlert("success", "Rider added", true)
    },
    async addFileLink() {
      let result = await saveFileLink(this.eventId, this.artist.id, {'url': this.linkToFile})
      this.linkToFile = ''
      this.artist.files.push(result)
    },
    async removeFile(fileId) {
      await removeFile(this.eventId, this.artist.id, fileId)
      this.artist.files = this.artist.files.filter(c => c.id !== fileId)
    },
    async removeRiderFile(riderId) {
      // TODO
      //await removeFile(this.eventId, this.artistId, fileId)
      this.artist.rider_files = this.artist.rider_files.filter(c => c.id !== fileId)
    },
    pickFile(e) {
      // https://blog.openreplay.com/building-a-custom-file-upload-component-for-vue/
      // Check if file is selected

      let input = this.$refs.rider_file
      let file = input.files
      if (e.target.files && e.target.files[0]) {
        file = e.target.files[0]
        this.uploadFieldName = file.name
      }
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },
    pickFileFile(e) {
      // https://blog.openreplay.com/building-a-custom-file-upload-component-for-vue/
      // Check if file is selected


      let input = this.$refs.other_file
      let file = input.files
      if (e.target.files && e.target.files[0]) {
        file = e.target.files[0]
        this.uploadFieldNameFile = file.name
      }
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },
    closeAddRider() {
      this.showUploadRider = false
      this.$refs.file.value = null
      this.$refs.other_file.value = null
    },
  },
}
</script>

<template>
  <div>
    <div id="artist-riders" class="mt-5" v-if="'Riders' in form.sections">
      <h4 class="text-uppercase">{{ form.sections['Riders'].name }}</h4>
      <div class="d-flex justify-content-between">
        <div>
          <div class="col-9" v-html="form.sections['Riders'].message">
          </div>
          <p>
            File size is limited to 10MB.
          </p>
        </div>
        <div class="">
          <div class="subtitle2 border rounded border-primary p-2">
            DEADLINE: {{ artist.rider_deadline | formatDate }}
          </div>
        </div>
      </div>
      <div class="card p-3 my-2">
        <div v-for="file in artist.rider_files" v-bind:key="'rider_files-'+file.id"
             class="mb-3 d-flex justify-content-between">
          <div class="">
            <div class="item-title mb-2">Name</div>
            <div><a :href="file.url" target="_blank">{{ file.filename }}</a></div>
          </div>
          <div v-if="ridersAllowed && !artist.form_submitted" class="d-flex align-items-end">
            <button @click="removeFile(file.id)" class="btn btn-primary">
              <Icon name="trash" width="15" height="15"/>
            </button>
          </div>
        </div>
        <div v-if="ridersAllowed && !artist.form_submitted" class="mt-3">
          <div class="row">
            <div class="col">
              <form v-on:submit.prevent="uploadRiderFile">
                <div class="item-title mb-2">
                  Choose file
                </div>
                <div class="row">
                  <div>
                    <input id="file-upload" type="file" ref="file" @change="pickFile($event)"
                           :name="uploadFieldName"/>
                  </div>
                </div>
                <button class="col mt-2 btn btn-primary">
                  <div v-if="addRiderLoading" class="spinner-border-sm text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <span v-else>Upload file</span>
                </button>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="artist-files" class="mt-5" v-if="'Files' in form.sections">
      <h4 class="text-uppercase">{{ form.sections['Files'].name }}</h4>
      <div class="d-flex justify-content-between">
        <div>
          <div class="col-9" v-html="form.sections['Files'].message">
          </div>
          <p>
            File size limit is 10MB, for larger files please save the link to the file (Dropbox, Google drive, etc).
          </p>
        </div>
        <div class="">
          <div class="subtitle2 border rounded border-primary p-2">
            DEADLINE: {{ artist.other_files_deadline | formatDate }}
          </div>
        </div>
      </div>
      <div class="card p-3 my-2">
        <div v-for="file in artist.files" v-bind:key="'rider_files-'+file.id"
             class="mb-3 d-flex justify-content-between">
          <div class="">
            <div class="item-title mb-2">Name</div>
            <div><a :href="file.url" target="_blank">{{ file.filename }}</a></div>
          </div>
          <div v-if="filesAllowed && !artist.form_submitted" class="d-flex align-items-end">
            <button @click="removeFile(file.id)" class="btn btn-primary">
              <Icon name="trash" width="15" height="15"/>
            </button>
          </div>
        </div>
        <div class="mt-3" v-if="filesAllowed && !artist.form_submitted">
          <div class="row">
            <div class="col">
              <form v-on:submit.prevent="uploadFile">
                <div class="item-title mb-2">
                  Choose file
                </div>
                <div class="row">
                  <div>
                    <input id="file-upload2" type="file" ref="other_file" @change="pickFileFile($event)"
                           :name="uploadFieldNameFile"/>
                  </div>
                </div>
                <button class="col mt-2 btn btn-primary">
                  <div v-if="addFileLoading" class="spinner-border-sm text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <span v-else>Upload file</span>
                </button>
              </form>
            </div>
            Or
            <div class="col">
              <div class="item-title mb-2">
                Add link to file Dropbox, Google drive, etc
              </div>
              <div class="row">
                <div>
                  <input type="text"
                         v-model="linkToFile"/>
                </div>
              </div>
              <button class="btn btn-primary mt-2" @click="addFileLink()">Add link</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
