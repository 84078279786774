import {post, get, postWithTimeout} from "@/http";

export function login(username, password) {
  return post('/api/auth', {
    username: username,
    password: password
  })
}

export function getEventCheckInStats(eventId) {
  return get(`/api/event/${eventId}/check_in_stats`)
}

export function getEvents() {
  return get("/api/events")
}

export function getAllEvents() {
  return get("/api/all-events")
}

export function getEvent(eventId) {
  return get(`/api/events/${eventId}`)
}

export function removeEvent(eventId) {
  return post(`/api/events/${eventId}/delete`)
}

export function saveEvent(eventId, data) {
  return post(`/api/event/${eventId}`, data)
}

export function getEventPeople(eventId) {
  return get(`/api/event/${eventId}/people`)
}

export function checkInPerson(eventId, body) {
  return postWithTimeout(`/api/event/${eventId}/check_in`, body)
}

export function getEventUsers(eventId) {
  return get(`/api/event/${eventId}/users`)
}

export function removeAccountUser(accountId, userId) {
  return post(`/api/accounts/${accountId}/users/${userId}/delete`)
}

export function addAccountUser(data) {
  return post(`/api/accounts/users`, data)
}

export function deleteAccountUser(userId) {
  return post(`/api/accounts/users/${userId}/delete`, {})
}

export function updateAccountUser(userId, data) {
  return post(`/api/accounts/users/${userId}`, data)
}

export function addGroupPerson(eventId, unitId, groupId, data) {
  return post(`/api/event/${eventId}/unit/${unitId}/group/${groupId}/add_person`, data)
}

export function addUnitPerson(eventId, unitId, data) {
  return post(`/api/event/${eventId}/unit/${unitId}/add_person`, data)
}

export function getAccessSystem(eventId) {
  return get(`/api/event/${eventId}/access_system`)
}

export function saveAccessSystem(eventId, accessSystem) {
  return post(`/api/event/${eventId}/save_access`, accessSystem)
}

export function startEditingMatrix(eventId) {
  return post(`/api/event/${eventId}/start_edit_access`)
}

export function addEventProfile(eventId, data) {
  return post(`/api/event/${eventId}/add_profile`, data)
}

export function addEventWristband(eventId, data) {
  return post(`/api/event/${eventId}/add_wristband`, data)
}

export function addEventBadge(eventId, data) {
  return post(`/api/event/${eventId}/add_badge`, data)
}

export function addEventTime(eventId, data) {
  return post(`/api/event/${eventId}/add_time`, data)
}

export function addEventArea(eventId, data) {
  return post(`/api/event/${eventId}/add_area`, data)
}

export function removeCheckIn(eventId, data) {
  return post(`/api/event/${eventId}/remove_check_in`, data)
}

export function checkInAllGuests(eventId, data) {
  return post(`/api/event/${eventId}/check_in_all`, data)
}

export function removeEventPerson(eventId, data) {
  return post(`/api/events/${eventId}/delete_person`, data)
}

export function updatePerson(eventId, personId, data) {
  return post(`/api/events/${eventId}/update_person/${personId}`, data)
}

export function approvePerson(eventId, personId) {
  return post(`/api/events/${eventId}/approve_person/${personId}`)
}

export function removePersonApproval(eventId, personId) {
  return post(`/api/events/${eventId}/remove_approval/${person.id}`)
}

export function updateEventObject(eventId, objectType, objectId, data) {
  return post(`/api/event/${eventId}/${objectType}/${objectId}`, data)
}

export function deleteEventObject(eventId, objectType, objectId) {
  return post(`/api/event/${eventId}/${objectType}/${objectId}/delete`)
}

export function addEventUnit(eventId, data) {
  return post(`/api/event/${eventId}/units`, data)
}

export function getEventUnits(eventId) {
  return get(`/api/event/${eventId}/units`)
}

export function addEventUnitGroup(eventId, unitId, data) {
  return post(`/api/event/${eventId}/unit/${unitId}`, data)
}

export function addEventUser(eventId, data) {
  return post(`/api/event/${eventId}/add_user`, data)
}

export function removeEventUser(eventId, userId) {
  return post(`/api/event/${eventId}/remove_user/${userId}`, {})
}

export function updateEventUser(eventId, userId, data) {
  return post(`/api/event/${eventId}/users/${userId}`, data)
}

export function addEventGroup(eventId, unitId, data) {
  return post(`/api/event/${eventId}/unit/${unitId}/add_group`, data)
}

export function eventUnitAddManager(eventId, unitId, data) {
  return post(`/api/event/${eventId}/unit/${unitId}/add_manager`, data)
}

export function eventUnitAddPerson(eventId, unitId, data) {
  return post(`/api/event/${eventId}/unit/${unitId}/add_person`, data)
}

export function getEventUnit(eventId, unitId) {
  return get(`/api/event/${eventId}/unit/${unitId}`)
}

export function getEventGroup(eventId, unitId, groupId) {
  return get(`/api/event/${eventId}/unit/${unitId}/group/${groupId}`)
}

export function deletePeople(eventId, data) {
  return post(`/api/event/${eventId}/delete_people`, data)
}

export function setProfileForPeople(eventId, profileId, data) {
  return post(`/api/event/${eventId}/set_profile_for_people`, {'profileId': profileId, 'people': data})
}

export function addEventMassGuests(eventId, unitId, groupId, guests) {
  return post(`/api/event/${eventId}/add_guests`, {
    'guests': guests,
    'unit_id': unitId,
    'group_id': groupId,
  })
}

export function eventCheckIfGuestsExist(eventId, guests) {
  return post(`/api/event/${eventId}/people/exists-all`, {
    'guests': guests
  })
}

export function eventCheckIfGuestExists(eventId, name) {
  return post(`/api/event/${eventId}/people/exists`, {
    'name': name
  })
}

export function addMultipleGuests(eventId, guests, unitId, groupId) {
  return post(`/api/event/${eventId}/people/add-multiple`, {
    'unitId': unitId,
    'groupId': groupId,
    'guests': guests
  })
}


export function deleteUnit(eventId, unitId) {
  return post(`/api/event/${eventId}/unit/${unitId}/delete`, {})
}

export function updateUnit(eventId, unitId, data) {
  return post(`/api/event/${eventId}/unit/${unitId}/update`, data)
}

export function deleteGroup(eventId, unitId, groupId) {
  return post(`/api/event/${eventId}/unit/${unitId}/group/${groupId}/delete`, {})
}

export function updateGroup(eventId, unitId, groupId, data) {
  return post(`/api/event/${eventId}/unit/${unitId}/group/${groupId}/update`, data)
}

export function getUserDashboard() {
  return get("/api/user_dashboard")
}

export function getEventLogs(eventId) {
  return get(`/api/events/${eventId}/logs`)
}

export function getEvenArtistsLogs(eventId) {
  return get(`/api/events/${eventId}/artists-logs`)
}

export function getCurrentPlan() {
  return get(`/api/payment-plans/current`)
}

export function getPaymentPlans() {
  return get('/api/payment-plans')
}

export function getAccountUsers() {
  return get('/api/accounts/users')
}

export function selectFreePlan() {
  return post('/api/payment-plans/select-free')
}

export function selectPaymentPlan(name) {
  return post('/api/payment-plans/select-plan', {name: name})
}

export function selectCustomPaymentPlan(name) {
  return post('/api/payment-plans/select-plan', {name: name})
}

export function createAdvancingForm(eventId, name, sections) {
  return post(`/api/event/${eventId}/advancing-forms`, {name: name, sections: sections})
}

export function getAdvancingForms(eventId) {
  return get(`/api/event/${eventId}/advancing-forms`)
}

export function getAdvancingForm(eventId, formId) {
  return get(`/api/event/${eventId}/advancing-forms/${formId}`)
}

export function saveFormTemplates(eventId, formId, data) {
  return post(`/api/event/${eventId}/advancing-forms/${formId}`, data)
}

export function saveArtistForms(eventId, data) {
  return post(`/api/event/${eventId}/artists/save-forms`, data)
}

export function changePassword(currentPassword, newPassword) {
  return post(`/api/change-password`, {'current_password': currentPassword, 'new_password': newPassword})
}

export function getEventData(id) {
  return get(`/api/event-data/${id}`)
}
