<template>
  <div>
    <div class="mt-3">
      <h1 class="float-start">Access matrix</h1>
      <button v-if="editing" v-on:click="editMatrix()" class="mt-3 float-end btn btn-primary px-3 py-2">
        <Icon name="edit" width="15" height="15" />
        Edit matrix
      </button>
    </div>
    <div class="w-100 d-flex flex-row">
    <div style="overflow-x: auto">
    <table class="table table-responsive mt-3">
      <tbody>
      <tr>
        <td style="border: none">
        </td>
        <td style="border: none" class="text-center" v-for="area in accessSystem.areas" :key="area.id">{{ area.name }}</td>
        <td style="border: none" class="text-center">Times</td>
        <td v-if="wristbands.length > 0" style="border: none" class="text-center">Wristband</td>
        <td v-if="badges.length > 0" style="border: none" class="text-center">Badge</td>
      </tr>

      <tr v-for="profile in accessSystem.profiles" :key="profile.id">
        <td style="border: none" class="text-center">{{ profile.name }}</td>
        <td :class="selectedItemClasses(profile.areas.includes(area.id))" v-for="area in accessSystem.areas"
            :key="area.id">
          <span v-if="profile.areas.includes(area.id)"><Icon name="check"/></span>
        </td>
        <td class="text-center align-middle access-matrix">
          {{ getName(profile.timeId, accessSystem.times) }}
        </td>
        <td v-if="wristbands.length > 0" class="text-center  align-middle access-matrix">
          {{ getName(profile.wristbandId, accessSystem.wristbands) }}
        </td>
        <td v-if="badges.length > 0" class="text-center align-middle">
          {{ getName(profile.badgeId, accessSystem.badges) }}
        </td>
      </tr>
      </tbody>
    </table>
    </div>
    </div>

    <h2 class="mb-5" style="margin-top:128px" v-if="wristbands.length > 0">Wristbands</h2>
    <div class="card mt-3 border-2" v-for="wristband in wristbands" v-bind:key="wristband.id">
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <div class="item-title">
              Name
            </div>
            <div>
              {{ wristband.name }}
            </div>
          </div>
          <div class="col-9">
            <img class="img-fluid" :src="wristband.picture_url"/>
          </div>
        </div>
      </div>
    </div>

    <h2 class="mb-5" style="margin-top:128px" v-if="badges.length > 0">Badges</h2>
    <div class="card mt-3 border-2" v-for="badge in badges" v-bind:key="badge.id">
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <div class="item-title">
              Name
            </div>
            <div>
              {{ badge.name }}
            </div>
          </div>
          <div class="col-9">
            <img class="img-fluid" :src="badge.picture_url" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {startEditingMatrix} from "@/api";
import Icon from "@/components/common/Icon";

export default {
  name: 'AccessSystemDisplay',
  components: {Icon},
  props: [
    'event',
    'accessSystem',
    'editing'
  ],
  computed: {
    badges() {
      return this.accessSystem.badges.filter(badge => badge.picture_url)
    },
    wristbands() {
      return this.accessSystem.wristbands.filter(w => w.picture_url)
    }
  },
  methods: {
    getName(id, list) {
      const items = list.filter(f => f.id === id)
      if (items.length > 0) {
        return items[0].name
      }

      return ''
    },
    async editMatrix() {
      await startEditingMatrix(this.accessSystem.event.id)
      this.$router.go()
    },
    selectedItemClasses(selected) {
      return {
        'text-center': true,
        'access-matrix': true,
        'align-middle': true,
        'bg-primary': selected === true,
        'text-light': selected === true
      }
    }
  },
}
</script>
