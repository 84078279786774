<template>
  <div>
    <div v-if="editing || (artist.hotels.length > 0 && event.accomodation_published)">
      <h2 class="my-5" id="artist-accomodation">Accommodation</h2>
      <h3 v-if="distinctHotels.size > 0">Your hotels</h3>
      <div v-for="hotel in distinctHotels" class="card card-body mt-3" v-bind:key="'hotel-'+hotel.id">
        <div class="row mt-2">
          <div class="item-title col">Hotel</div>
          <div class="item-title col">Address</div>
          <div class="item-title col">Check-in</div>
          <div class="item-title col">Check-out</div>
        </div>
        <div class="row">
          <div class="col">{{ hotel.name }}</div>
          <div class="col">{{ hotel.address }}</div>
          <div class="col">{{ hotel.check_in }}</div>
          <div class="col">{{ hotel.check_out }}</div>
        </div>
        <div class="row mt-2">
          <div class="item-title col">Breakfast</div>
        </div>
        <div class="row">
          <div class="col">{{ hotel.breakfast }}</div>
        </div>
        <template v-if="hotel.additional_information">
          <div class="row mt-2">
            <div class="item-title col">Additional information</div>
          </div>
          <div class="row">
            <div class="col" v-html="hotel.additional_information"></div>
          </div>
        </template>
      </div>
      <h3 v-if="distinctHotels.size > 0" class="mt-3">Your hotel bookings</h3>
      <div v-for="hotel in artist.hotels" v-bind:key="'accomodation-'+hotel.id" class="card card-body mt-3">
        <div class="row">
          <div class="item-title col">Hotel</div>
          <div class="item-title col">Check-in</div>
          <div class="item-title col">Check-out</div>
          <div class="col"></div>
        </div>
        <template v-if="editingAccommodation === hotel.id">
          <div class="row">
            <div class="col">
              <select class="form-select" v-model="selectedHotelId">
                <option disabled :value="null">Select hotel</option>
                <option v-for="hotel in hotels" :value="hotel.id" v-bind:key="'hotel-'+hotel.id">{{
                    hotel.name
                  }}
                </option>
              </select>
            </div>
            <div class="col"><input type="date" v-model="fromDate" class="form-control"/></div>
            <div class="col"><input type="date" v-model="toDate" class="form-control"/></div>
            <div class="col">
              <button class="btn btn-primary" @click="closeEditing(hotel.id)">
                <Icon name="close" width="15" height="15"/>
              </button>
              <button class="btn btn-primary ms-2" @click="saveEditedHotel()">
                <Icon name="check" width="15" height="15"/>
              </button>
            </div>
          </div>
          <div class="row mt-2">
            <div class="item-title col">Room type</div>
            <div class="item-title col">Names</div>
          </div>
          <div class="row">
            <div class="col">
              <input type="text" class="form-control" v-model="roomType"/>
            </div>
            <div class="col">
              <input type="text" class="form-control" v-model="names"/>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="row">
            <div class="col">{{ getHotelName(hotel.hotel_id) }}</div>
            <div class="col">{{ hotel.from| formatDate }}</div>
            <div class="col">{{ hotel.to | formatDate }}</div>
            <div class="col">
              <div v-if="editing === true">
                <button class="btn btn-primary" @click="editAccommodation(hotel.id)">
                  <Icon name="edit" width="15" height="15"/>
                </button>
                <button class="btn btn-secondary ms-2" @click="deleteAccommodation(hotel.id)">
                  <Icon name="trash"/>
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="item-title col">Room type</div>
            <div class="item-title col">Names</div>
          </div>
          <div class="row">
            <div class="col">
              {{ hotel.room_type }}
            </div>
            <div class="col">
              {{ hotel.names }}
            </div>
          </div>
        </template>
      </div>
      <div v-if="editing === true">
        <button class="mt-3 btn btn-primary" @click="addHotel()">Add accommodation</button>
        <div v-if="addingAccommodation === true">
          <div class="card card-body mt-3">
            <div class="row mt-2">
              <div class="item-title col-3">Hotel</div>
              <div class="item-title col-3">Check-in</div>
              <div class="item-title col-3">Check-out</div>
              <div class="item-title col"></div>
              <div class="item-title col"></div>
            </div>
            <div class="row mt-2">
              <div class="col-3">
                <select class="form-select" v-model="selectedHotelId">
                  <option disabled :value="null">Select hotel</option>
                  <option v-for="hotel in hotels" :value="hotel.id" v-bind:key="'hotel-'+hotel.id">{{
                      hotel.name
                    }}
                  </option>
                </select>
              </div>
              <div class="col-3"><input type="date" v-model="fromDate" class="form-control"/></div>
              <div class="col-3"><input type="date" v-model="toDate" class="form-control"/></div>
              <div class="col">
                <button class="ms-2 btn btn-primary" @click="closeAdding()">
                  <Icon name="close" width="15" height="15"/>
                </button>
              </div>
            </div>
            <div>
              <div class="row mt-3">
                <div class="item-title col">
                  Room type
                </div>
                <div class="item-title col">
                  Names
                </div>
              </div>
              <div class="row">
                <div class="col"><input type="text" class="form-control" v-model="roomType"/></div>
                <div class="col"><input type="text" class="form-control" v-model="names"/></div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4">
                <button class="btn btn-primary" @click="saveHotel()">
                  Save new accommodation
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "@/components/common/Icon"
import {deleteArtistAccommodation, saveArtistHotel, updateArtistHotel} from "@/artists_api";

export default {
  name: 'ArtistAccomodationItem',
  components: {Icon},
  props: ['artist', 'hotels', 'editedArtist', 'eventId', 'editing', 'event'],
  data() {
    return {
      editingAccommodation: null,
      addingAccommodation: false,
      fromDate: null,
      toDate: null,
      names: '',
      roomType: '',
      selectedHotelId: null
    }
  },
  methods: {
    addHotel() {
      this.addingAccommodation = true
      this.closeEditing()
    },
    closeAdding() {
      this.addingAccommodation = false
    },
    async saveHotel() {
      let hotel = {
        'id': this.selectedHotelId,
        'room_type': this.roomType,
        'names': this.names,
        'from': this.fromDate,
        'to': this.toDate
      }
      this.addingAccommodation = false
      let addedHotel = await saveArtistHotel(this.eventId, this.artist.id, hotel)
      this.artist.hotels.push(addedHotel)
      this.editedArtist = null
      this.selectedHotelId = null
      this.fromDate = null
      this.toDate = null
      this.comments = ''
    },
    async saveEditedHotel() {
      let hotel = this.artist.hotels.find(h => h.id === this.editingAccommodation)
      hotel.hotel_id = this.selectedHotelId
      hotel.from = this.fromDate
      hotel.to = this.toDate
      hotel.names = this.names
      hotel.room_type = this.roomType
      await updateArtistHotel(this.eventId, this.artist.id, this.editingAccommodation, hotel)
      this.closeEditing()
    },
    async deleteAccommodation(accommodationId) {
      if (confirm("Are you sure you want to delete this accommodation?")) {
        await deleteArtistAccommodation(this.eventId, this.artist.id, accommodationId).then(r => {
          this.artist.hotels = this.artist.hotels.filter(h => h.id !== accommodationId)
        })
      }
    },
    getHotelName(hotelId) {
      if (hotelId === null) {
        return ''
      }
      let hotel = this.hotels.find(hotel => hotel.id === hotelId)
      if (hotel) {
        return hotel.name
      }
    },
    editAccommodation(hotelId) {
      this.editingAccommodation = hotelId
      let hotel = this.artist.hotels.find(h => h.id === hotelId)
      this.editing = true
      this.selectedHotelId = hotel.hotel_id
      this.fromDate = hotel.from
      this.toDate = hotel.to
      this.names = hotel.names
      this.roomType = hotel.room_type
    },
    closeEditing() {
      this.editingAccommodation = null
      this.selectedHotelId = null
      this.fromDate = null
      this.toDate = null
      this.names = ''
      this.roomType = ''
    }
  }, computed: {
    distinctHotels() {
      const distinctHotels = new Set(this.artist.hotels.map(h => h.hotel_id))
      return this.hotels.filter(h => distinctHotels.has(h.id))
    }
  },
}
</script>
