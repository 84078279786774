<template>
  <div>
    <Navbar/>
    <AccountAdminMenu>
      <div>
        <h1>Extra features</h1>
      </div>
    </AccountAdminMenu>
  </div>
</template>

<script>
import Navbar from "@/components/common/Navbar.vue";
import Icon from "@/components/common/Icon";
import AccountAdminMenu from "@/components/AccountAdminMenu";

export default {
  name: "AccountExtraFeatures",
  components: {
    Navbar, AccountAdminMenu
  },
  data() {
    return {}
  },
  async mounted() {
  },
  methods: {},
}
</script>

<style scoped>

</style>
