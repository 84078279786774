<template>
  <Modal v-bind:show-modal.sync="isShown" :modal-class="'modal-full-screen modal-dialog-centered'">
    <div class="modal-content">
      <div class="modal-body">
        <div v-if="object">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Change {{objectType}}</h5>
              <button type="submit" class="btn btn-primary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="dismiss()">
                Close
              </button>
            </div>
            <div class="modal-body">
              <input class="form-control" type="text" v-model="object.name"/>
              <br/>
              <button @click="saveChanges()" type="button" class="btn btn-primary">Save</button>
              <button @click="deleteObject()" v-if="object.can_delete" type="button" class="mx-3 btn btn-danger">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/common/Modal.vue";
import store from "@/store";
import axios from "axios";
import {deleteEventObject, updateEventObject} from "@/api";

export default {
  name: "Modalobject",
  components: {Modal},
  props: ['addPicture', 'eventId'],
  data() {
    return {
      isShown: false,
      editing: false,
      objetType: 'object',
      object: null
    }
  },
  async mounted() {

  },
  methods: {
    present(object, objectType) {
      this.object = object
      this.objectType = objectType
      this.isShown = true
    },
    dismiss() {
      this.isShown = false
      this.editing = false
    },
    toggleEditing() {
      this.editing = !this.editing
    },
    async saveChanges() {
      await updateEventObject(this.eventId, this.objectType, this.object.id, {name: this.object.name})

      this.dismiss()
    },
    async deleteObject() {
      await deleteEventObject(this.eventId, this.objectType, this.object.id)

      this.$emit('deleted', this.object, this.objectType)
      this.dismiss()
    }
  }
}
</script>
