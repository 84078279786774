<script>
export default {
  name: "PasswordInput",
  props: [
    'password'
    ],
  methods: {
    updatePassword() {
      this.$emit('input', this.$refs.p.value)
    },
    toggleVisible() {
      if (this.$refs.p.type === 'password') {
        this.$refs.p.type = 'text'
        this.$refs.eyeIcon.textContent = 'Hide'
      } else {
        this.$refs.p.type = 'password'
        this.$refs.eyeIcon.textContent = '👁️'
      }
    }
  }
}
</script>

<template>
  <div style="flex-direction: row; display: flex; align-items: center">
    <input id="passwordInput" class="ms-2 form-control form-control-lg" ref='p' type="password" :value="password" @input="updatePassword()">
    <span ref='eyeIcon' class="ms-2" @click="toggleVisible()">&#128065;</span>
  </div>
</template>
