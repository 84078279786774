import {get, post} from "@/http";

export function getEventArtists(eventId) {
  return get(`/api/event/${eventId}/artists`)
}

export function addArtist(eventId, data) {
  return post(`/api/event/${eventId}/artists`, data)
}

export function deleteEventArtist(eventId, artistId) {
  return post(`/api/event/${eventId}/artists/${artistId}/delete`)
}

export function getEventArtis(eventId, artistId) {
  return get(`/api/event/${eventId}/artists/${artistId}`)
}

export function addArtistContact(eventId, artistId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/contacts`, data)
}

export function addArtistPerformer(eventId, artistId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/performers`, data)
}

export function addArtistCrew(eventId, artistId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/crew`, data)
}

export function addArtistBackstageGuest(eventId, artistId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/backstage_guests`, data)
}

export function addArtistGuest(eventId, artistId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/guests`, data)
}

export function addArtistRiderFile(eventId, artistId, formData) {
  return post(`/api/event/${eventId}/artists/${artistId}/rider_files`, formData)
}

export function addArtistFile(eventId, artistId, formData) {
  return post(`/api/event/${eventId}/artists/${artistId}/files`, formData)
}

export function updateArtist(eventId, artistId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}`, data)
}

export function getStages(eventId) {
  return get(`/api/event/${eventId}/stages`)
}

export function addStage(eventId, data) {
  return post(`/api/event/${eventId}/stages`, data)
}

export function saveTimetableItem(eventId, data) {
  return post(`/api/event/${eventId}/timetable`, data)
}

export function getTimetable(eventId) {
  return get(`/api/event/${eventId}/timetable`)
}

export function getStagesTimetable(eventId) {
  return get(`/api/event/${eventId}/timetable/stages`)
}

export function getArtistPage(artistId) {
  return get(`/api/artist-page/${artistId}`)
}

export function getEventGeneralInformation(eventId) {
  return get(`/api/event/${eventId}/general-information`)
}

export function saveEventGeneralInformation(eventId, data) {
  return post(`/api/event/${eventId}/general-information`, data)
}

export function removeArtistContact(eventId, artistId, contactId) {
  return post(`/api/event/${eventId}/artists/${artistId}/contacts/${contactId}/delete`)
}

export function updateEditedContact(eventId, artistId, contactId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/contacts/${contactId}`, data)
}

export function removeArtistPerformer(eventId, artistId, performerId) {
  return post(`/api/event/${eventId}/artists/${artistId}/performers/${performerId}/delete`)
}

export function updateEditedPerformer(eventId, artistId, performerId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/performers/${performerId}`, data)
}

export function removeArtistCrew(eventId, artistId, crewId) {
  return post(`/api/event/${eventId}/artists/${artistId}/crew/${crewId}/delete`)
}

export function updateEditedCrew(eventId, artistId, crewId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/crew/${crewId}`, data)
}

export function removeArtistGuest(eventId, artistId, guestId) {
  return post(`/api/event/${eventId}/artists/${artistId}/guests/${guestId}/delete`)
}

export function updateEditedGuest(eventId, artistId, guestId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/guests/${guestId}`, data)
}

export function removeFile(eventId, artistId, fileId) {
  return post(`/api/event/${eventId}/artists/${artistId}/rider_files/${fileId}/delete`)
}

export function addArtistUser(eventId, artistId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/users`, data)
}

export function removeArtistUser(eventId, artistId, userId) {
  return post(`/api/event/${eventId}/artists/${artistId}/users/${userId}/delete`)
}

export function updateEditedUser(eventId, artistId, userId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/users/${userId}`, data)
}

export function saveArtistLimitsForm(eventId, artistId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/save-limits-form`, data)
}

export function saveArtistMessage(eventId, artistId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/message`, data)
}

export function saveRiderLink(eventId, artistId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/rider_link`, data)
}

export function saveFileLink(eventId, artistId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/file_link`, data)
}

export function setArtistUserPassword(data) {
  return post(`/api/set-artist-user`, data)
}

export function getUserByKey(key) {
  return get(`/api/user-by-key?key=${key}`)
}

export function saveAndSubmitArtist(eventId, artistId) {
  return post(`/api/event/${eventId}/artists/${artistId}/save_and_submit`, {})
}

export function setArtistNotSubmitted(eventId, artistId) {
  return post(`/api/event/${eventId}/artists/${artistId}/set_not_submitted`, {})
}

export function sendPasswordEmail(eventId, artistId, userId) {
  return post(`/api/event/${eventId}/artists/${artistId}/users/${userId}/send_password_mail`, {})
}

export function addHotel(eventId, data) {
  return post(`/api/event/${eventId}/hotels`, data)
}

export function getEventHotels(eventId) {
  return get(`/api/event/${eventId}/hotels`)
}

export function getEventHotel(eventId, hotelId) {
  return get(`/api/event/${eventId}/hotels/${hotelId}`)
}

export function updateHotel(eventId, hotelId, hotel) {
  return post(`/api/event/${eventId}/hotels/${hotelId}`, hotel)
}

export function saveArtistHotel(eventId, artistId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/hotel`, data)
}

export function updateArtistHotel(eventId, artistId, accomodationId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/hotel/${accomodationId}`, data)
}

export function deleteArtistAccommodation(eventId, artistId, accomodationId) {
  return post(`/api/event/${eventId}/artists/${artistId}/hotel/${accomodationId}/delete`, {})
}

export function getEventSchedules(eventId) {
  return get(`/api/event/${eventId}/schedules`)
}

export function addEventSchedule(eventId, data) {
  return post(`/api/event/${eventId}/schedules`, data)
}

export function saveArtistScheduleBooking(eventId, scheduleId, data) {
  return post(`/api/event/${eventId}/schedules/${scheduleId}/artists`, data)
}

export function updateArtistSchedule(eventId, artistScheduleId, data) {
  return post(`/api/event/${eventId}/schedules/update/${artistScheduleId}`, data)
}

export function deleteArtistPerformance(eventId, artistScheduleId) {
  return post(`/api/event/${eventId}/schedules/delete/${artistScheduleId}`)
}

export function publishSchedules(eventId,) {
  return post(`/api/event/${eventId}/schedules/publish`)
}

export function unpublishSchedules(eventId,) {
  return post(`/api/event/${eventId}/schedules/unpublish`)
}

export function publishAccomodation(eventId,) {
  return post(`/api/event/${eventId}/hotels/publish`)
}

export function unpublishAccomodation(eventId,) {
  return post(`/api/event/${eventId}/hotels/unpublish`)
}

export function saveArtistTransfer(eventId, artistId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/transfers`, data)
}

export function updateArtistTransfer(eventId, artistId, transferId, data) {
  return post(`/api/event/${eventId}/artists/${artistId}/transfers/${transferId}`, data)
}

export function deleteArtistTransfer(eventId, artistId, transferId) {
  return post(`/api/event/${eventId}/artists/${artistId}/transfers/${transferId}/delete`, {})
}

export function getEventTags(eventId) {
  return get(`/api/event/${eventId}/tags`)
}

export function addTagToArtist(eventId, artistId, data) {
  return post(`/api/event/${eventId}/artist-tags/${artistId}`, data)
}

export function removeArtistTag(eventId, artistId, data) {
  return post(`/api/event/${eventId}/artist-tags/${artistId}/remove`, data)
}
