<template>
  <div>
    <Navbar/>
    <div class="container-fluid">
      <h1 class="mb-5">Account users</h1>
        <button type="button" class="btn btn-primary" @click="startAddingUser()">Add user</button>
      <div v-for="user in users" v-bind:key="user.id">
        <div class="card mt-3">
          <div class="card-body">
            <div class="d-flex">
              <div class="me-auto">{{user.name}}, {{user.role}}</div>
              <div @click="deleteUser(user.id)"><Icon name="trash"/></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal v-bind:show-modal.sync="addingNewUser" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Add new user</h5>
            </div>
            <div class="modal-body">
              <div class="row mt-3">
                <div class="col-auto">
                  <div class="item-title mb-2">
                    Name
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="name"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-auto">
                  <div class="item-title mb-2">
                    Role
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <select class="form-select" autocomplete="off" v-model="selectedRole">
                          <option selected="selected" :value="null">Select role</option>
                          <option v-for="role in roles" :value="role.id" v-bind:key="role.name">{{ role.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="closeUserAdding()">
                Close
              </button>
              <button type="button" class="btn btn-primary" @click="addUser">Add user</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {addAccountUser, getAccountUsers, removeAccountUser} from "@/api";
import store from "@/store";
import Navbar from "@/components/common/Navbar.vue";
import Icon from "@/components/common/Icon";
import Modal from "@/components/common/Modal.vue";

export default {
  name: "MyAccount",
  components: {Modal, Icon, Navbar},
  data() {
    return {
      users: [],
      user_email: '',
      new_role: '',
      selectedRole: null,
      addingNewUser: false,
      roles: [
        {id: 'check-in', name: 'check in'},
        {id: 'manager', name: 'manager'},
        {id: 'security', name: 'security'}
      ]
    }
  },
  async mounted() {
    getAccountUsers(store.getUser().account_id).then(result => this.users = result)
  },
  methods: {
    deleteUser(user_id) {
      removeAccountUser(store.getUser().account_id, user_id)
    },
    addUser() {
      addAccountUser(store.getUser().account_id, {'user_email': this.user_email, 'role': this.new_role})
    },
    startAddingUser() {
      this.addingNewUser = true
    },
    closeUserAdding() {
      this.addingNewUser = false
    }
  },
}
</script>
