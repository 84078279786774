<template>
  <div>
    <ArtistNavbar currentPage="Artists"/>

    <ArtistPageMenu v-if="event" :event="event" currentPage="Schedule" :artist="artist">
      <h1>Schedule</h1>

      <div v-if="!event.schedule_published" class="d-flex justify-content-center w-100 mt-5">
        <h4 class="text-uppercase mt-5">Informative content coming soon, stay tuned!</h4>
      </div>

      <div v-else-if="artist">
        <ArtistSchedule :artistId="artist.id" :eventId="event.id"/>
      </div>
    </ArtistPageMenu>
  </div>
</template>
<script>
import ArtistNavbar from '@/components/ArtistNavbar.vue'
import ArtistPageMenu from "@/components/ArtistPageMenu";
import store from "@/store";
import {getArtistPage, getEventArtists, getEventSchedules, getStages} from "@/artists_api";
import ArtistSchedule from "@/views/artist/ArtistSchedule";

export default {
  name: 'ArtistPageTimetable',
  components: {ArtistSchedule, ArtistPageMenu, ArtistNavbar},
  props: [],
  data() {
    return {
      artistId: null,
      artist: null,
      event: null,
      form: null,
    }
  },
  async mounted() {
    this.artistId = store.getArtist()
    await getArtistPage(this.artistId).then(result => {
      this.artist = result.artist
      this.event = result.event
      this.eventId = this.event.id
      this.form = result.artist.form
    })
    this.artists = await getEventArtists(this.eventId)
    this.schedules = await getEventSchedules(this.eventId)
    this.stages = await getStages(this.eventId)
  }
}
</script>
