<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="People"/>
    <div v-if="event && group" id="" class="container-fluid">

      <div class="d-flex flex-row mt-3">
        <div class="flex-grow-1">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Event', params: { id: event.id }}" class="text-disabled text-decoration-none">
                  {{ event.name }}
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name: 'EventPeople', params: { id: event.id }}"
                             class="text-disabled text-decoration-none">
                  People
                </router-link>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                <router-link :to="{name: 'EventUnit', params: { id: event.id, unitId: group.unitId }}"
                             class="text-disabled text-decoration-none">
                  {{ unit.name }}
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">{{ group.name }}</li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="mb-5 d-flex justify-content-start">
        <h3>Group: {{ group.name }}</h3>

        <div class="ms-2">
          <button @click="changeGroupName" class="me-2 btn btn-primary">
            <Icon width="15" height="15" name="edit"/>
          </button>
        </div>
        <div class="flex-grow-1"></div>
        <div class="justify-content-end">
          <div class="align-self-end me-2 d-inline-flex">
            <select class="form-select me-2" v-model="group.profile_id">
              <option :value="null">Set profile</option>
              <option v-for="profile in profiles" :value="profile.id" v-bind:key="profile.id">{{ profile.name }}
              </option>
            </select>

            <button @click="updateGroupProfile(group.id)" class="btn btn-primary">Set&nbsp;group&nbsp;profile</button>
          </div>
        </div>

      </div>
      <button @click="openAddNewPerson" class="mt-2 mb-3 btn btn-primary me-2">Add new person</button>
      <button @click="openMultiAdd" class="mt-2 mb-3 btn btn-primary">Multi-add</button>

      <hr/>
      <PeopleData :people="people" :event="event" :selectedUnit="parseInt(unitId)" :selectedGroup="parseInt(groupId)"
                  :unit_names="unit_names"
                  :units="units"
                  :group_names="group_names" :profiles="profiles"
                  :unitId="unitId"
                  :groupId="groupId"
                  v-on:addedPeople="addedPeople"
                  v-on:updatedProfile="updatedProfile"
                  v-on:updatedPerson="updatedPerson"
      />
      <Modal v-bind:show-modal.sync="changingGroupName" :modal-class="'modal-full-screen modal-dialog-centered'">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-content overflow-auto">
              <div class="modal-header">
                <h5 class="modal-title">Edit group name</h5>

              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-auto">
                    <div class="item-title">
                      Name
                    </div>
                    <div>
                      <div class="row">
                        <div class="col-auto">
                          <input type="text" class="form-control pe-0" v-model="groupName"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-secondary"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="close()">
                  Cancel
                </button>
                <button type="button" class="btn btn-primary" @click="saveGroupName">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal v-bind:show-modal.sync="addingOnePerson" :modal-class="'modal-full-screen modal-dialog-centered'">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-content overflow-auto">
              <div class="modal-header">
                <h5 class="modal-title">Add new person</h5>
              </div>
              <div class="modal-body">
                <div v-if="personExists" class="text-danger">
                  Person with such a name already exists, do you still want to add them?
                </div>
                <div class="row mt-3">
                  <div class="col-auto">
                    <div class="item-title mb-2">
                      Name
                    </div>
                    <div>
                      <div class="row">
                        <div class="col-auto">
                          <input type="text" class="form-control pe-0" v-model="personName"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-auto">
                    <div class="item-title mb-2">
                      Comments
                    </div>
                    <div>
                      <div class="row">
                        <div class="col-auto">
                          <input type="text" class="form-control pe-0" v-model="personComments"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-auto">
                    <div class="item-title mb-2">
                      Extra
                    </div>
                    <div>
                      <div class="row">
                        <div class="col-auto">
                          <input type="text" class="form-control pe-0" v-model="personExtra"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="cancelAddNewPerson">
                  Cancel
                </button>
                <button v-if="!personExists" type="button" class="btn btn-primary" @click="checkIfExistsAndAdd">Add new person</button>
                <button v-if="personExists" type="button" class="btn btn-primary" @click="addPerson">Add person again</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal v-bind:show-modal.sync="multiAddOpened" :modal-class="'modal-lg modal-full-screen modal-dialog-centered'">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-content overflow-auto">
              <div class="modal-header">
                <h5 class="modal-title">Multi-add to group {{group.name}}</h5>
                <div class="col-auto p-0">
                </div>
              </div>
              <div class="modal-body" v-if="multiAddStep === 1">
                <textarea class="form-control w-100" rows="10" v-model="multiAddPeopleAsText"></textarea>
                <p>Add each new entry onto a new row. The entry format is:<br/>Jane Jones +3, comment : Unit : Group</p>
              </div>
              <div class="modal-body" v-if="multiAddStep === 2">
                <table class="table">
                  <thead>
                  <tr>
                    <th>Name</th>
                    <th>Comments</th>
                    <th>Extra</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="guest in multiAddGuestsList" v-bind:key="'guest-' + guest.id">
                    <td>
                      <div class="d-flex">
                        <input type="text" class="form-control" v-model="guest.name"/>
                        <span v-if="guest.exists" class="ms-2 text-danger">!!</span>
                      </div>
                    </td>
                    <td><input type="text" class="form-control" v-model="guest.comments"/></td>
                    <td><input type="text" class="form-control" style="width: 3em" v-model="guest.guests"/></td>
                    <td><input type="checkbox" v-model="guest.selected"/></td>
                  </tr>
                  </tbody>
                </table>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-primary" @click="removeSelectedPeople">Remove</button>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button type="submit" class="btn btn-primary"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="cancelMultiAdd()">
                  Cancel
                </button>
                <button v-if="multiAddStep === 2" class="btn btn-primary me-2" @click="saveAllPeople">Save</button>
                <button v-if="multiAddStep === 1" type="button" class="btn btn-primary" @click="continueMultiAdd">Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import store from '../store'
import Navbar from '@/components/common/Navbar.vue'
import MassAddPeople from "@/components/MassAddPeople";
import UnitGroupPeople from "@/components/UnitGroupPeople";
import {
  addEventUnitGroup,
  addGroupPerson, addMultipleGuests,
  deleteGroup,
  deleteUnit, eventCheckIfGuestExists, eventCheckIfGuestsExist,
  getEvent,
  getEventGroup,
  updateGroup,
  updateUnit
} from "@/api";
import PeopleData from "@/components/PeopleData";
import Breadcrumbs from "@/components/Breadcrumbs";
import Modal from "@/components/common/Modal.vue";
import Icon from "@/components/common/Icon";

export default {
  name: 'EventGroup',
  props: ['eventId', 'unitId', 'groupId'],
  components: {
    Navbar,
    PeopleData,
    Modal,
    Icon
  },
  data() {
    return {
      unit: null,
      units: [],
      event: null,
      group: null,
      people: [],
      addPersonFields: [],

      group_names: [],
      unit_names: [],
      wristbands: [],
      badges: [],
      profiles: [],
      loaded: false,
      groupName: '',
      changingGroupName: false,

      personExists: false,
      addingOnePerson: false,
      personName: '',
      personComments: '',
      personExtra: 0,
      personUnitId: '',
      personGroupId: '',

      multiAddPeopleAsText: '',
      multiAddOpened: false,
      newPersonAddOpened: false,
      multiAddStep: null,
      multiAddGuestsList: [],
    }
  },
  methods: {
    cancelAddNewPerson() {
      this.addingOnePerson = false
      this.personComments = ''
      this.personName = ''
      this.personExtra = 0
      this.personGroupId = null
      this.personUnitId = null
      this.personExists = false
    },
    cancelMultiAdd() {
      this.multiAddOpened = false
      this.multiAddPeopleAsText = ''
      this.multiAddGuestsList = []
    },
    continueMultiAdd() {
      eventCheckIfGuestsExist(this.eventId, this.multiAddPeopleAsText).then((result) => {
        this.multiAddStep = 2
        this.multiAddGuestsList = result
      })
    },
    async saveAllPeople() {
      let result = await addMultipleGuests(this.eventId, this.multiAddGuestsList, this.unitId, this.groupId)
      this.people.push(...result)
      this.cancelMultiAdd()
    },
    removeSelectedPeople() {
      this.multiAddGuestsList = this.multiAddGuestsList.filter(g => g.selected !== true)
    },

    openAddNewPerson() {
      this.addingOnePerson = true
    },
    openMultiAdd() {
      this.multiAddOpened = true
      this.multiAddStep = 1
    },
    addedPeople(added) {
      this.people = [...this.people, ...added]
    },
    async addPerson() {
      addGroupPerson(this.eventId, this.unitId, this.groupId, {
        name: this.personName,
        comments: this.personComments,
        extra: this.personExtra
      }).then(result => {
        this.people.push(result)
        this.cancelAddNewPerson()
      })
    },
    async checkIfExistsAndAdd() {
      const exists = await eventCheckIfGuestExists(this.eventId, this.personName)
      if (exists.exists) {
        this.personExists = true
      } else {
        this.addPerson()
      }
    },
    changeGroupName() {
      this.changingGroupName = true
      this.groupName = this.group.name
    },
    updateGroupProfile(groupId) {
      updateGroup(this.event.id, this.unitId, groupId, {profileId: this.group.profile_id}).then(() => {
        for (let person of this.people) {
          person.profile_id = this.group.profile_id
        }
        store.addAlert("success", "Profile updated")
      })
    },
    async deleteGroup() {
      if (confirm("Are you sure you want to delete this group?")) {
        await deleteGroup(this.eventId, this.unitId, this.groupId)
      }
    },
    async saveGroupName() {
      await updateGroup(this.eventId, this.unitId, this.groupId, {name: this.groupName})
      this.group.name = this.groupName
      this.close()
    },
    close() {
      this.changingGroupName = false
    },
    async addGroup() {
      const group = await addEventUnitGroup(this.eventId, this.unitId, {name: this.groupName})
      this.groups.push(group)
      this.groupName = ''
    },
    updatedPerson(personId, data) {
      let person = this.people.find(p => p.id === personId)
      person.name = data.name
      person.comments = data.comments
      person.extra = data.extra
      person.blocked = data.blocked
      person.pending = data.pending
    },
    updatedProfile(profileId, updatedPeople) {
      this.people.forEach(p => {
        if (updatedPeople.includes(p.id)) {
          p.profile_id = profileId
        }
      })
    },
  },
  async mounted() {
    const result = await getEventGroup(this.eventId, this.unitId, this.groupId)

    this.unit = result.unit
    this.group = result.group
    this.people = result.people
    this.event = result.event

    const eventResult = await getEvent(this.eventId)
    this.event = eventResult.event
    this.units = eventResult.units
    this.group_names = eventResult.group_names
    this.unit_names = eventResult.unit_names
    this.wristbands = eventResult.wristbands
    this.badges = eventResult.badges
    this.profiles = eventResult.profiles

    this.loaded = true
  }
}
</script>
