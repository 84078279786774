<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="AccessSystem"></Navbar>

  <div class="container-fluid">
    <template v-if="accessSystem !== null">
      <div class="d-flex">
      <div class="flex-grow-1">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link :to="{name: 'Event', params: { id: event.id }}" class="text-disabled text-decoration-none">
                {{ event.name }}
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Access matrix</li>
          </ol>
        </nav>
      </div>
      </div>
      <AccessSystemDisplay v-if="accessSystem.saved" :accessSystem="accessSystem" :event="event" :editing="true" />
      <AccessSystemEditing v-else :accessSystem="accessSystem" :eventId="eventId" />
    </template>
  </div>
  </div>
</template>
<script>

import Navbar from '@/components/common/Navbar.vue'
import AccessSystemEditing from "@/components/AccessSystemEditing";
import AccessSystemDisplay from "@/components/AccessSystemDisplay";
import {getAccessSystem} from "@/api";

export default {
  name: 'AccessSystem',
  props: ['eventId'],
  components: {
    AccessSystemEditing,
    AccessSystemDisplay,
    Navbar,
  },
  data() {
    return {
      event: null,
      accessSystem: null,
    }
  },
  async mounted() {
    this.accessSystem = await getAccessSystem(this.eventId)
    this.event = this.accessSystem.event
  }
}
</script>
