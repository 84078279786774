<template>
  <div>
    <Navbar></Navbar>
    <div class="container-fluid">

      <h1>Add a new event</h1>
      <div class="card elevation-16 mb-3">
        <div class="card-body">
          <form v-on:submit.prevent="addEvent">
            <div class="card-body">
              <div class="row">
                <div class="col-4">
                  <div class="item-title mb-2">
                    Event name
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input type="text" class="mb-2 form-control pe-0" v-model="name" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="item-title mb-2">
                    Start time
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input type="datetime-local" class="mb-2 form-control pe-0" v-model="start_time" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="item-title mb-2">
                    End time
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input type="datetime-local" class="mb-2 form-control pe-0" v-model="end_time" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <input type="submit" class="btn btn-primary" value="Add event"/>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Navbar from '@/components/common/Navbar.vue'
import store from "@/store";

export default {
  name: 'AddEvent',
  components: {Navbar},
  data() {
    return {
      end_time: '',
      start_time: '',
      name: '',
    }
  },
  methods: {
    async addEvent() {
      const headers = {
        "Authorization": "Bearer " + store.jwt()
      }
      await axios.post('/api/events', {
          name: this.name,
          start: this.start_time,
          end: this.end_time
        },
        {headers})

      this.$router.replace({name: 'Events'})
    }
  }
}
</script>
