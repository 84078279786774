<template>
  <div class="container-fluid ps-1">
    <div class="row flex-nowrap">
      <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
        <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 min-vh-100">
          <a href="/" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-decoration-none">
            <h5 class="fs-5 d-none d-sm-inline text-uppercase">{{ event.name }}</h5>
          </a>
          <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
            <li :class="[currentPage === 'GeneralInformation' ? 'menu-active': 'menu-itme']">
              <router-link :to="{name: 'ArtistPageGeneralInformation' }" class="nav-link">
                General information
              </router-link>
            </li>
            <li :class="[currentPage === 'Form' ? 'menu-active': 'menu-itme']">
              <div class=" accordion-flush " id="accordionExample">
                <div class="accordion-item">
                  <div class="accordion-header d-flex" id="headingOne">
                    <router-link :to="{name: 'ArtistAdvancingForm' }" class="nav-link">
                      Advancing information
                    </router-link>
                    <Icon name="chevron-down"/>
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <Icon name="chevron-down" height="15" width="15"/>
                    </button>
                  </div>
                  <div id="collapseOne" class="accordion-collapse show" aria-labelledby="headingOne"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <li class="artist-dropdown">
                        <router-link :to="{name: 'ArtistAdvancingForm', hash: '#contacts' }" class="nav-link">
                          Contacts
                        </router-link>
                      </li>
                      <li class="artist-dropdown">
                        <router-link :to="{name: 'ArtistAdvancingForm', hash: '#people' }" class="nav-link">
                          People
                        </router-link>
                      </li>
                      <li class="artist-dropdown">
                        <router-link :to="{path: '/artist-page', hash: '#artist-riders' }" class="nav-link">
                          Riders
                        </router-link>
                      </li>
                      <li class="artist-dropdown">
                        <router-link :to="{path: '/artist-page', hash: '#artist-files' }" class="nav-link">
                          Files
                        </router-link>
                      </li>
                      <!--
                      <li class="artist-dropdown">
                        <router-link :to="{path: '/artist-page', hash: '#transfers' }" class="nav-link">
                          Transfers
                        </router-link>
                      </li>-->
                      <li class="artist-dropdown" v-if="artist.hotels.length > 0">
                        <router-link :to="{name: 'ArtistAdvancingForm', hash: '#artist-accomodation' }"
                                     class="nav-link">
                          Accommodation
                        </router-link>
                      </li>
                      <li class="artist-dropdown">
                        <router-link :to="{path: '/artist-page', hash: '#artist-schedule' }" class="nav-link">
                          Schedule
                        </router-link>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li :class="[currentPage === 'MyAccount' ? 'menu-active': 'menu-itme']">
              <router-link :to="{name: 'ArtistMyAccount' }" class="nav-link">
                My account
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="col">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "@/components/common/Icon.vue";

export default {
  name: "ArtistPageMenu",
  props: ['event', 'currentPage', 'artist'],
  components: {Icon},

}
</script>
