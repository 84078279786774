<template>
  <div>
    <div class="mt-5 d-flex justify-content-between" id="artist-schedule">
      <h2>Schedule</h2>
    </div>
    <div v-if="event.schedule_published !== true" class="d-flex justify-content-center">
      Information coming soon, stay tuned!
    </div>
    <div v-else>
      <div v-for="schedule in artist.schedules" v-bind:key="'schedule-'+schedule.id">
        <h4 class="mt-3">{{schedule.schedule}}</h4>
        <div v-for="stage in stages" v-bind:key="'schedule-'+schedule.id + '-stage-'+stage.id">
          <div v-if="schedule.stage_id === stage.id">
            <div v-bind:key="'stage-'+stage.id" class="col-md-4 mt-2 mb-5">
              <strong class="mt-3 mb-5">{{ stage.name }}</strong>
              <br/>
              <template v-for="a in getSchedule(schedule.schedule_id).artists">
                <div v-if="a.stage_id === stage.id" v-bind:key="'a-' + a.id"
                     :class="{'text-danger': a.artist_id === artistId, 'fw-bolder': a.artist_id === artistId}">
                  {{ a.start_time }}&nbsp;&#8212;&nbsp;{{ a.end_time }} {{ getArtist(a.artist_id).name }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getArtistPage, getEventArtists,
  getEventSchedules,
  getStages,
} from "@/artists_api";
export default {
  name: "ArtistSchedule",
  components: {
  },
  methods: {
    getArtist(artistId) {
      return this.artists.filter(a => a.id === artistId)[0]
    },
    getSchedule(scheduleId) {
      return this.schedules.find(s => s.id === scheduleId)
    }
  },
  props: ['eventId', 'event', 'artistId'],
  data() {
    return {
      artist: null,
      artists: [],
      schedules: [],
      stages: [],
    }
  },
  async mounted() {
    let artist_object = await getArtistPage(this.artistId)
    this.artist = artist_object.artist
    this.artists = await getEventArtists(this.eventId)
    this.schedules = await getEventSchedules(this.eventId)
    this.stages = await getStages(this.eventId)
  }
}
</script>

