<template>
  <div id="" class="container">
    <Navbar :event="event" :eventId="eventId"></Navbar>
    <template v-if="event">
      <div class="row">
        <div class="col-12">
          <h5 class="mt-3">People</h5>
          <FilterUnitGroup class="w-25" v-on:selectedUnit="selectUnit" v-on:selectedGroup="selectGroup" :units="units" />
          <!--
          <h4>Approved</h4>
          <button class="btn-outline-primary btn" @click="showApproved('all')">All</button>
          <button class="btn-outline-primary btn" @click="showApproved(null)">Unknown</button>
          <button class="btn-outline-primary btn" @click="showApproved('yes')">Approved</button>
          <button class="btn-outline-primary btn" @click="showApproved('no')">Not approved</button>
  -->
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="item-title">
            Set profile
          </div>
          <div class="d-flex flex-row">
            <select type="select" v-model="profileId">
              <option value="null" disabled>Choose profile</option>
              <option v-for="profile in profiles" v-bind:key="profile.id"
                      v-bind:value="profile.id">{{ profile.name }}
              </option>
            </select>
            <button class="ms-2 btn btn-secondary" @click="setProfileForSelected(profileId)">Update selected</button>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <div class="item-title">
            Search
          </div>
          <input type="text" v-model="textFilter"/>
        </div>
      </div>
      <PersonDataItem v-for="person in selectedPersons" v-bind:key="person.id"
                      :person="person" :unit_names="unit_names" :group_names="group_names" :profiles="profiles"
                      :eventId="eventId" v-on:removed="onRemovePerson"
      />
    </template>
  </div>
</template>
<script>
import axios from 'axios'
import store from '../store'
import Navbar from '@/components/common/Navbar.vue'
import PersonDataItem from "@/components/PersonDataItem";
import {getEvent} from "@/api";
import FilterUnitGroup from "@/components/FilterUnitGroup";

export default {
  name: 'Event',
  props: ['eventId'],
  components: {
    FilterUnitGroup,
    PersonDataItem,
    Navbar,
  },
  data() {
    return {
      event: null,
      people: [],
      fields: [],
      units: [],
      unit_names: [],
      group_names: [],
      selectedGroup: null,
      selectedUnit: null,
      approved: 'all',
      wristbands: [],
      wristbandId: null,
      badges: [],
      badgeId: null,
      profiles: [],
      profileId: null,
      textFilter: ''
    }
  },
  async mounted() {
    const headers = {
      "Authorization": "Bearer " + store.jwt()
    }

    const result = await getEvent(this.eventId)
    const people_result = await axios.get("/api/event/" + this.eventId + "/people", {headers})
    this.event = result.event
    this.people = people_result.data.people
    this.fields = people_result.data.fields
    this.units = result.units
    this.group_names = result.group_names
    this.unit_names = result.unit_names
    this.wristbands = result.wristbands
    this.profiles = result.profiles
    this.badges = result.badges
  },
  methods: {
    onRemovePerson(id) {
      this.people = this.people.filter(person => person.id != id)
    },
    selectGroup(group) {
      this.selectedGroup = group
    },
    selectUnit(unit) {
      this.selectedUnit = unit
      this.selectedGroup = null
    },
    selectAll() {
      this.selectedGroup = null
      this.selectedUnit = null
    },
    showApproved(value) {
      this.approved = value
    },
    filterPersonApproved(person) {
      if (this.approved === "all")
        return true
      else if (this.approved === person.approved)
        return true
      else
        return false
    },
    filterPersonGroupUnit(p) {
      if (this.selectedUnit === null && this.selectedGroup === null) {
        return true
      } else {
        if (p.unit_id !== null && p.unit_id === this.selectedUnit && this.selectedGroup === null)
          return true
        else if (this.selectedGroup !== null && p.group_id === this.selectedGroup) {
          return true
        }
      }
    },
    filterText(p) {
      if (this.textFilter.length < 2) {
        return true
      }

      if (p.comments && p.comments.toLowerCase().includes(this.textFilter.toLowerCase())) {
        return true
      }

      if (p.name.toLowerCase().includes(this.textFilter.toLowerCase())) {
        return true
      } else {
        return false
      }

      return false
    },
    async setProfileForSelected(profileId) {
      let personIds = []
      this.selectedPersons.forEach((p) => personIds.push(p.id))
      const result = await axios.post(`/api/events/${this.eventId}/set_profiles`,
        {
          profile_id: profileId,
          persons: personIds
        })
    },
  },
  computed: {
    selectedPersons: function () {
      return this.people.filter(p => {
        return this.filterPersonApproved(p) && this.filterPersonGroupUnit(p) && this.filterText(p)
      })
    }
  },
}
</script>
