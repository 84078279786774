<template>
  <div>
    <h4 id="transfers" class="text-uppercase mt-5">Transfers</h4>
    <div class="d-flex p-0 m-0 justify-content-between">
      <div class="col-9" v-html="form.sections['Performers'].message">
      </div>
    </div>
    <div class="card p-3 my-2 container" v-for="transfer in artist.transfers" v-bind:key="'transfer-'+transfer.id">
      <template v-if="transfer.id === editedTransferId">
        <form id="editingForm" @submit.prevent>
          <div class="row mt-2">
            <div class="item-title col">Flight number</div>
            <div class="item-title col">Departure from</div>
            <div class="item-title col">Departure time</div>
          </div>
          <div class="row mt-2">
            <div class="col"><input required type="text" v-model="flight_number" class="form-control"/></div>
            <div class="col"><input required type="text" v-model="departure_from" class="form-control"/></div>
            <div class="col"><input required type="datetime-local" v-model="departure_time" class="form-control"/></div>
          </div>
          <div class="row mt-2">
            <div class="item-title col">Passengers</div>
            <div class="item-title col">Arrival to</div>
            <div class="item-title col">Arrival time</div>
          </div>
          <div class="row mt-2">
            <div class="col"><input required min="0" max="100" type="number" v-model="passengers" class="form-control"/>
            </div>
            <div class="col"><input required type="text" v-model="arrival_to" class="form-control"/></div>
            <div class="col"><input required type="datetime-local" v-model="arrival_time" class="form-control"/></div>
          </div>
          <div class="d-flex mt-3">
            <button class="btn btn-primary" @click="updateTransfer()">Save transfer</button>
            <button class="btn btn-secondary ms-2" @click="cancelEditing()">Cancel</button>
          </div>
        </form>
      </template>
      <template v-else>
        <div class="row mt-2">
          <div class="item-title col">Flight number</div>
          <div class="item-title col">Departure from</div>
          <div class="item-title col">Departure time</div>
        </div>
        <div class="row mt-2">
          <div class="col">{{ transfer.flight_number }}</div>
          <div class="col">{{ transfer.departure_from }}</div>
          <div class="col">{{ transfer.departure_time | formatDateTime }}</div>
        </div>
        <div class="row mt-2">
          <div class="item-title col">Passengers</div>
          <div class="item-title col">Arrival to</div>
          <div class="item-title col">Arrival time</div>
        </div>
        <div class="row mt-2">
          <div class="col">{{ transfer.passengers }}</div>
          <div class="col">{{ transfer.arrival_to }}</div>
          <div class="col">{{ transfer.arrival_time | formatDateTime }}</div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button class="btn btn-primary" @click="editTransfer(transfer)">
              <Icon name="edit" width="15" height="15"/>
            </button>
            <button class="btn btn-secondary ms-2" @click="deleteTransfer(transfer.id)">
              <Icon name="trash"/>
            </button>
          </div>
        </div>
      </template>
    </div>
    <div v-if="adding" class="card p-3 my-2 container">
      <form id="addForm" @submit.prevent>
        <div class="row mt-2">
          <div class="item-title col">Flight number</div>
          <div class="item-title col">Departure from</div>
          <div class="item-title col">Departure time</div>
        </div>
        <div class="row mt-2">
          <div class="col"><input required type="text" v-model="flight_number" class="form-control"/></div>
          <div class="col"><input required type="text" v-model="departure_from" class="form-control"/></div>
          <div class="col"><input required type="datetime-local" v-model="departure_time" class="form-control"/></div>
        </div>
        <div class="row mt-2">
          <div class="item-title col">Passengers</div>
          <div class="item-title col">Arrival to</div>
          <div class="item-title col">Arrival time</div>
        </div>
        <div class="row mt-2">
          <div class="col"><input required min="0" max="100" type="number" v-model="passengers" class="form-control"/>
          </div>
          <div class="col"><input required type="text" v-model="arrival_to" class="form-control"/></div>
          <div class="col"><input required type="datetime-local" v-model="arrival_time" class="form-control"/></div>
        </div>
        <div class="d-flex mt-3">
          <button class="btn btn-primary" @click="saveTransfer()">Save transfer</button>
          <button class="btn btn-secondary ms-2" @click="cancelAddingTransfer()">Cancel</button>
        </div>
      </form>
    </div>
    <button v-if="!adding" class="btn btn-primary" @click="startAddTransfer()">Add new transfer</button>
  </div>
</template>

<script>
import {deleteArtistTransfer, saveArtistTransfer, updateArtistTransfer} from "@/artists_api";
import Icon from "@/components/common/Icon.vue";

export default {
  name: "ArtistTransfers",
  components: {Icon},
  props: ['eventId', 'event', 'artistId', 'artist', 'form'],
  data() {
    return {
      adding: false,
      editedTransferId: null,
      arrival_to: '',
      arrival_time: '',
      departure_time: '',
      departure_from: '',
      flight_number: '',
      passengers: 0
    }
  },
  methods: {
    startAddTransfer() {
      this.adding = true
    },
    cancelAddingTransfer() {
      this.adding = false
    },
    async saveTransfer() {
      let valid = document.getElementById("addForm").checkValidity()
      if (!valid) {
        return
      }
      saveArtistTransfer(this.eventId, this.artistId, {
        arrival_to: this.arrival_to,
        arrival_time: this.arrival_time,
        departure_from: this.departure_from,
        departure_time: this.departure_time,
        flight_number: this.flight_number,
        passengers: this.passengers
      }).then(result => {
        this.artist.transfers.push(result)
        this.adding = false
      })
    },
    editTransfer(transfer) {
      this.editedTransferId = transfer.id
      this.arrival_to = transfer.arrival_to
      this.arrival_time = transfer.arrival_time.toString().slice(0, 16)
      this.departure_from = transfer.departure_from
      this.departure_time = transfer.departure_time.toString().slice(0, 16)
      this.flight_number = transfer.flight_number
      this.passengers = transfer.passengers
    },
    async deleteTransfer(transferId) {
      if (confirm("Are you sure you want to delete this transfer?")) {
        await deleteArtistTransfer(this.eventId, this.artist.id, transferId).then(r => {
          this.artist.transfers = this.artist.transfers.filter(t => t.id !== transferId)
        })
      }
    },
    cancelEditing() {
      this.editedTransferId = null
      this.arrival_to = ''
      this.arrival_time = ''
      this.departure_from = ''
      this.departure_time = ''
      this.flight_number = ''
      this.passengers = ''
    },
    updateTransfer() {
      let valid = document.getElementById("editingForm").checkValidity()
      if (!valid) {
        return
      }
      let updated = {
        id: this.editedTransferId,
        arrival_to: this.arrival_to,
        arrival_time: this.arrival_time,
        departure_from: this.departure_from,
        departure_time: this.departure_time,
        flight_number: this.flight_number,
        passengers: this.passengers
      };
      updateArtistTransfer(this.eventId, this.artistId, this.editedTransferId, updated).then(result => {
        let transfer = this.artist.transfers.find(t => t.id === this.editedTransferId)
        transfer.arrival_to = this.arrival_to
        transfer.arrival_time = this.arrival_time
        transfer.departure_from = this.departure_from
        transfer.departure_time = this.departure_time
        transfer.flight_number = this.flight_number
        transfer.passengers = this.passengers

        this.cancelEditing()
      })
    }
  },
}
</script>
