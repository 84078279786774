<template>
  <inline-svg width="14" height="14"
    class="icon" :class="iconClass" :src="require(`../../assets/icons/${name}.svg`)"
  />
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "Icon",
  props: ["name", "iconClass"],
  components: { InlineSvg },
};
</script>
