import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PortalVue from 'portal-vue'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore' // ES 2015
dayjs.extend(isSameOrBefore);

import './assets/scss/main.scss'

Vue.use(PortalVue)
Vue.filter('formatDateTime', function (value) {
  if (!value || value.length === 0)
    return ''
  return dayjs(value).format('DD.MM.YYYY HH:mm')
})

Vue.filter('formatDate', function (value) {
  if (!value || value.length === 0)
    return ''
  return dayjs(value).format('DD.MM.YYYY')
})

Vue.filter('formatDateShort', function (value) {
  if (!value || value.length === 0)
    return ''
  return dayjs(value).format('DD.MM')
})

Vue.filter('formatTime', function (value) {
  if (!value || value.length === 0)
    return ''
  return dayjs(value).format('HH:mm')
})

Vue.filter('round100', function(value) {
  return naiveRound(value, 1)
})

function naiveRound(num, decimalPlaces = 0) {
  var p = Math.pow(10, decimalPlaces);
  return Math.round(num * p) / p;
}

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
