<template>
<nav class="navbar navbar-expand px-4">
  <span class="navbar-brand">
      <span class="brand">MISTIN</span>
  </span>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav  mx-auto">
      <li v-if="artists.length > 1" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          {{current_artist_name}}
        </a>
        <ul class="dropdown-menu">
          <li v-for="artist in artists" v-bind:key="'artist-'+ artist.id">
            <a class="dropdown-item" href="#" @click="setArtist(artist.id)">{{artist.name}}</a></li>
        </ul>
      </li>
    </ul>
  </div>
  <ul class="navbar-nav account">
    <li><span class="nav-link">{{ user_e_mail }}</span></li>
    <li><a href="#" class="nav-link" @click.prevent="logout()">Log out</a></li>
  </ul>
</nav>
</template>
<script>
import axios from 'axios'
import store from '../store'

export default {
  name: 'Navbar',
  props: ['event', 'eventId', 'currentPage'],
  data() {
    return {
      artists: [],
      account_name: null,
      current_artist_name: '',
      user_e_mail: ''
    }
  },
  mounted() {
    this.artists = store.getArtists()
    this.current_artist_name = this.artists.filter(a => a.id == store.getArtist())[0].name
    this.user_e_mail = store.getUser().e_mail
  },
  methods: {
    setArtist(id) {
      store.setArtist(id)
      this.$router.go()
    },
    async logout() {
      store.logOut()
      const result = await axios.post("/api/logout")
      this.$router.replace({name: 'Login'})
    }
  },
}
</script>
