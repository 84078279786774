<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <div class="container-fluid">

      <h1>Change artist data</h1>
      <div class="card elevation-16 mb-3" v-if="artist">
        <div class="card-body">
          <form v-on:submit.prevent="updateArtist">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="item-title mb-2">
                    Full name
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="text" class="mb-2 form-control pe-0" v-model="artist.name"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="item-title mb-2">
                    Contacts deadline
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input type="date" id="contact" class="mb-2 form-control pe-0" v-model="contact_deadline"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="item-title mb-2">
                    Performers limit
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input type="number" class="mb-2 form-control pe-0" v-model="performers_limit"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="item-title mb-2">
                    Performers deadline
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input type="date" class="mb-2 form-control pe-0" v-model="performers_deadline"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="item-title mb-2">
                    Crew limit
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input type="number" class="mb-2 form-control pe-0" v-model="crew_limit"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="item-title mb-2">
                    Crew deadline
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input type="date" class="mb-2 form-control pe-0" v-model="crew_deadline"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="item-title mb-2">
                    Guestlist limit
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input type="number" class="mb-2 form-control pe-0" v-model="guestlist_limit"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="item-title mb-2">
                    Guestlist deadline
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input type="date" class="mb-2 form-control pe-0" v-model="guestlist_deadline"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="item-title mb-2">
                    Technical & hospitality rider deadline
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input type="date" class="mb-2 form-control pe-0" v-model="rider_deadline"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="item-title mb-2">
                    Other files (show visuals, etc) deadline
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input type="date" class="mb-2 form-control pe-0" v-model="other_files_deadline"/>
                    </div>
                  </div>
                </div>
              </div>
              <h3 class="mt-3">Tags</h3>
              <div class="row">
                <div class="col">
                  <span class="me-2" v-for="tag in artist.tags" v-bind:key="'tag-' + tag.id">{{ tag.name }}
                    <button class="btn btn-primary ms-2" @click.prevent="removeTag(tag.id)">
                      <Icon name="trash" width="15" height="15"/>
                    </button>
                  </span>
                </div>
                <div class="col">
                  <select class="form-select" v-model="newTagId">
                    <option selected="selected" :value="null">Choose tag</option>
                    <option v-for="tag in tags" :value="tag.id" v-bind:key="tag.id">{{ tag.name }}
                    </option>
                  </select>
                  <button @click.prevent="addTag()" class="mt-2 btn btn-primary">Add</button>
                </div>
              </div>
              <div class="mt-3">
                <input type="submit" class="btn btn-primary" value="Save"/>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from '@/components/common/Navbar.vue'
import {getEventArtis, removeArtistTag, getStages, updateArtist, addTagToArtist} from "@/artists_api";
import {getEvent} from "@/api";
import {getEventTags} from "../artists_api";
import Icon from "../components/common/Icon.vue";

export default {
  name: 'EditArtist',
  components: {Icon, Navbar},
  props: ['eventId', 'artistId'],
  data() {
    return {
      name: '',
      artist: null,
      event: null,
      stages: [],
      tags: [],
      contact_deadline: null,
      crew_deadline: null,
      performers_deadline: null,
      other_files_deadline: null,
      guestlist_limit: null,
      guestlist_deadline: null,
      crew_limit: 0,
      performers_limit: 0,
      rider_deadline: null,
      newTagId: null
    }
  },
  async mounted() {
    this.artist = await getEventArtis(this.eventId, this.artistId)
    this.crew_deadline = this.artist.crew_deadline
    this.guestlist_deadline = this.artist.guestlist_deadline
    this.guestlist_limit = this.artist.guestlist_limit
    this.contact_deadline = this.artist.contact_deadline
    this.performers_deadline = this.artist.performers_deadline
    this.rider_deadline = this.artist.rider_deadline
    this.other_files_deadline = this.artist.other_files_deadline
    this.crew_limit = this.artist.crew_limit
    this.performers_limit = this.artist.performers_limit
    this.event = await getEvent(this.eventId)
    this.stages = await getStages(this.eventId)
    this.tags = await getEventTags(this.eventId)
  },
  methods: {
    async updateArtist() {
      await updateArtist(this.eventId, this.artist.id, {
        'crew_deadline': this.crew_deadline,
        'contact_deadline': this.contact_deadline,
        'performers_deadline': this.performers_deadline,
        'rider_deadline': this.rider_deadline,
        'other_files_deadline': this.other_files_deadline,
        'crew_limit': this.crew_limit,
        'guestlist_limit': this.guestlist_limit,
        'guestlist_deadline': this.guestlist_deadline,
        'performers_limit': this.performers_limit,
        'name': this.artist.name
      })
      this.$router.replace({name: 'Artists'})
    },
    async addTag() {
      addTagToArtist(this.eventId, this.artist.id, {'id': this.newTagId}).then(result => {
        this.artist.tags = result
      })
    },
    async removeTag(tagId) {
      removeArtistTag(this.eventId, this.artist.id, {'id': tagId}).then(result => {
        this.artist.tags = result
      })
    }
  }
}
</script>
