<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <ArtistsMenu :eventId="eventId" active="files" :event="event" v-if="event">
      <div v-if="event" class="d-flex flex-row mt-3">
        <div class="flex-grow-1">
          <Breadcrumbs :event="event" currentPageName="Artists"/>
        </div>
      </div>


      <span v-if="event">
        </span>

      <h2>Files</h2>


    </ArtistsMenu>
  </div>
</template>
<script>
import Navbar from '@/components/common/Navbar.vue'
import {getEventArtists} from "@/artists_api";
import {getEvent, getEventData} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import ArtistsMenu from "@/components/ArtistsMenu";

export default {
  name: 'ArtistsFiles',
  components: {
    ArtistsMenu,
    Navbar, Breadcrumbs

  },
  props: ['eventId'],
  data() {
    return {
      artists: [],
      event: null
    }
  },
  async mounted() {
    this.artists = await getEventArtists(this.eventId)
    this.event = await getEventData(this.eventId)
  }
}
</script>
