<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <ArtistsMenu :eventId="eventId" active="form" :event="event" v-if="event">
      <div class="d-flex justify-content-between">
        <h1>Advancing form</h1>
        <div class="mt-3">
          <button @click="active='forms'"
                  v-bind:class="[active === 'forms' ? 'btn-primary' : 'btn-outline-primary', 'btn', 'me-2']">
            Forms
          </button>
          <button @click="active='per-artist'"
                  v-bind:class="[active !== 'forms' ? 'btn-primary' : 'btn-outline-primary', 'btn']">
            Edit per artist
          </button>
        </div>
      </div>

      <div v-if="active === 'forms'">
        <h4>CREATED FORMS</h4>
        <div v-for="form in forms" class="card mt-3" v-bind:key="form.id">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>{{ form.name }}</div>
              <div>
                <router-link :to="{name: 'AdvancingForm', params: { eventId: eventId, formId: form.id }}"
                             href="#" class="link-primary px-0 align-middle ms-1">
                  Edit
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div v-if="addingForm">
          <div class="card border-2 mt-2">
            <div class="card-body pt-4">
              <form @submit.prevent>
                <div class="row">
                  <div class="col-4">
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" placeholder="Form name" class="mb-2 form-control pe-0"
                               v-model="newFormName"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">Sections</div>
                  <div class="col">
                    <ul>
                      <li v-for="section in newFormSections" :key="'section-'+section">{{ section }}
                        <button class="btn btn-secondary" @click="removeNewSection(section)">
                          <Icon width="15" height="15" name="trash"/>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row d-flex align-content-start">
                  <div class="col">
                    <select v-model="newSection">
                      <option selected :value="null">Select a section</option>
                      <option v-for="section in availableSections" :value="section" :key="'new-section-'+section">
                        {{ section }}
                      </option>
                    </select>
                    <button class="ms-2 btn btn-primary" @click="addNewSection()">Add section</button>
                  </div>
                </div>
              </form>
              <div class="row mt-2">
                <div class="col">
                  <button class="btn btn-primary" @click="saveNewForm()">Save new form</button>
                  <button class="ms-2 btn btn-primary" @click="cancelAddingNewForm()">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button v-if="!addingForm" class="btn btn-primary mt-2" @click="openAddNewForm()">Add new form</button>
      </div>
      <div v-else>
        <h4 class="text-uppercase">Edit per artist</h4>
        <input type="text" v-model="filterArtistName" placeholder="Search artists"/>
        <div v-for="artist in selectedArtists" v-bind:key="artist.id" class="card card-body mt-3">
          <div class="row">
            <div class="col-2">
              <div class="item-title mb-2">
                Artist
              </div>
              <div class="row">
                <div class="col-auto">
                  {{ artist.name }}
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="item-title mb-2">
                Advancing form
              </div>
              <div class="row">
                <div class="col-auto">
                  <div v-if="editLimitsArtist === artist">
                    <select class="form-control form-select" v-model="artist.advancing_form_id">
                      <option :value="null">Select form</option>
                      <option v-for="form in forms" :value="form.id" v-bind:key="'form-'+form.id">{{ form.name }}
                      </option>
                    </select>
                  </div>
                  <div v-else>
                    {{ formName(artist) || 'Not selected' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="item-title mb-2">
                <Icon name="microphone" class="me-2"/>
                Performers
              </div>
              <div class="row">
                <div class="col-auto">
                  <div v-if="editLimitsArtist === artist">
                    <input class="form-control" type="number" min="0" v-model="artist.performers_limit"/>
                  </div>
                  <div v-else>
                    {{ artist.performers_limit || '0' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="item-title mb-2">
                <Icon name="tool" class="me-2"/>
                Crew
              </div>
              <div class="row">
                <div class="col-auto">
                  <div v-if="editLimitsArtist === artist">
                    <input class="form-control" type="number" min="0" v-model="artist.crew_limit"/>
                  </div>
                  <div v-else>
                    {{ artist.crew_limit || '0' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="item-title mb-2">
                <Icon name="account" class="me-2"/>
                Guestlist
              </div>
              <div class="row">
                <div v-if="editLimitsArtist === artist">
                  <input class="form-control" type="number" min="0" v-model="artist.guestlist_limit"/>
                </div>
                <div v-else>
                  <div class="col-auto">
                    {{ artist.guestlist_limit || '0' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="item-title mb-2">
                <div class="invisible">H</div>
              </div>
              <div class="row">
                <div v-if="editLimitsArtist === artist" class="col-auto">
                  <button @click="editLimitsArtist = null; saveArtist(artist.id)" class="btn btn-primary">Confirm
                  </button>
                </div>
                <div v-else class="col-auto d-flex justify-content-end">
                  <button @click="setDeadlines(artist)" class="me-2 btn btn-outline-primary">Deadlines</button>
                  <button @click="editLimits(artist)" class="btn btn-primary">
                    <Icon name="edit"/>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ArtistsMenu>
    <Modal v-bind:show-modal.sync="editingDeadlines" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content" v-if="editDeadlinesArtist">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-body">
              <h5 class="modal-title">DEADLINES</h5>
              <div class="">
                <div class="float-start w-50 pe-3 mt-2">
                  <div>
                    Contacts
                  </div>
                  <div>
                    <div class="col-auto">
                      <input class="form-control" type="date" v-model="editDeadlinesArtist.contact_deadline"/>
                    </div>
                  </div>
                </div>
                <div class="float-start w-50 mt-2">
                  <div>
                    Performers
                  </div>
                  <div>
                    <div class="col-auto">
                      <input class="form-control" type="date" v-model="editDeadlinesArtist.performers_deadline"/>
                    </div>
                  </div>
                </div>
                <div class="float-start w-50 pe-3 mt-2">
                  <div>
                    Crew
                  </div>
                  <div>
                    <div class="col-auto">
                      <input class="form-control" type="date" v-model="editDeadlinesArtist.crew_deadline"/>
                    </div>
                  </div>
                </div>
                <div class="float-start w-50 pe-2 mt-2">
                  <div>
                    Guestlist
                  </div>
                  <div>
                    <div class="col-auto">
                      <input class="form-control" type="date" v-model="editDeadlinesArtist.guestlist_deadline"/>
                    </div>
                  </div>
                </div>
                <div class="float-start w-50 mt-2 pe-2">
                  <div>
                    Riders
                  </div>
                  <div>
                    <div class="col-auto">
                      <input class="form-control" type="date" v-model="editDeadlinesArtist.rider_deadline"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="saveArtist(editDeadlinesArtist.id); editingDeadlines = false; editDeadlinesArtist = null;">
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import Navbar from '@/components/common/Navbar.vue'
import {getEventArtists, updateArtist} from "@/artists_api";
import {createAdvancingForm, getAdvancingForms, getEvent, getEventData, saveArtistForms} from "@/api";
import ArtistsMenu from "@/components/ArtistsMenu";
import Icon from "@/components/common/Icon";
import Modal from "@/components/common/Modal.vue";

export default {
  name: 'AdvancingForms',
  components: {
    Icon,
    Navbar, ArtistsMenu, Modal
  },
  props: ['eventId', 'formId'],
  data() {
    return {
      forms: [],
      event: null,
      artists: [],
      active: 'forms',
      addingForm: false,
      editLimitsArtist: null,
      editDeadlinesArtist: null,
      editDeadlineModal: null,
      editingDeadlines: false,
      filterArtistName: '',
      newFormName: '',
      newSection: null,
      newFormSections: [],
      formSections: ['Intro', 'Contacts', 'Performers', 'Crew', 'Guestlist', 'Riders', 'Files',
        'Accommodation', 'Transfers']
    }
  },
  methods: {
    addNewSection() {
      console.log(this.newSection)
      if (this.newSection !== null) {
        this.newFormSections.push(this.newSection)
        this.newSection = null
      }
    },
    removeNewSection(section) {
      this.newFormSections = this.newFormSections.filter(s => s !== section)
    },
    openAddNewForm() {
      this.addingForm = true
    },
    cancelAddingNewForm() {
      this.addingForm = false
      this.newFormName = ''
    },
    async saveNewForm() {
      let result = await createAdvancingForm(this.eventId, this.newFormName, this.newFormSections)
    },
    hasSection(artist, sectionType) {
      let f = this.forms.find(f => f.id === artist.advancing_form_id)

      if (f && f.sections) {
        return f.sections.includes(sectionType);
      }
      return false
    },
    async saveArtistForms() {
      await saveArtistForms(this.eventId, {'artists': this.artists})
    },
    async saveArtist(artistId) {
      let a = this.artists.find(artist => artist.id === artistId)
      await updateArtist(this.eventId, artistId, a)
    },
    formName(artist) {
      let f = this.forms.find(f => f.id === artist.advancing_form_id)
      if (f) {
        return f.name
      }
      return ''
    },
    setDeadlines(artist) {
      this.editingDeadlines = true
      this.editDeadlinesArtist = artist
    },
    editLimits(artist) {
      this.editLimitsArtist = artist
    },
    filterArtist(p) {
      if (p.name && p.name.toLowerCase().includes(this.filterArtistName.toLowerCase())) {
        return true
      }

      if (p.name.toLowerCase().includes(this.filterArtistName.toLowerCase())) {
        return true
      } else {
        return false
      }

      return false
    },
  },
  async mounted() {
    if (this.$route.query.active && this.$route.query.active === 'artists') {
      this.active = 'artists'
    }
    this.forms = await getAdvancingForms(this.eventId)
    this.event = await getEventData(this.eventId)
    this.artists = await getEventArtists(this.eventId)
  },
  computed: {
    selectedArtists: function () {
      return this.artists.filter(p => {
        return this.filterArtist(p)
      })
    },
    availableSections: function () {
      let sections = []
      this.formSections.forEach(section => {
        if (this.newFormSections.find(s => s === section) === undefined) {
          sections.push(section)
        }
      })
      return sections
    }
  }
}
</script>
