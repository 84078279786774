<template>
  <div>
    <h1>Accommodation</h1>
    <div v-if="!event.accomodation_published" class="py-3 d-flex justify-content-center w-100">
      <h4 class="text-uppercase">Informative content coming soon, stay tuned!</h4>
    </div>
    <div v-else>
      <div v-if="artist && hotels.length > 0">
        <div class="row">
          <div class="col item-title">
            Hotel
          </div>
          <div class="col item-title">
            Address
          </div>
          <div class="col item-title">
            Check-in
          </div>
          <div class="col item-title">
            Check-out
          </div>
        </div>
        <div class="row">
          <div class="col">
            {{ getHotel.name }}
          </div>
          <div class="col">
            {{ getHotel.address }}
          </div>

          <div class="col">
            {{ getHotel.check_in }}
          </div>
          <div class="col">
            {{ getHotel.check_out }}
          </div>
        </div>
        <div class="row mt-3">
          <div class="col item-title">
            From
          </div>
          <div class="col item-title">
            To
          </div>
          <div class="col item-title">
            Breakfast
          </div>
          <div class="col item-title">
            <span v-if="artist.hotel.comments">Comments</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            {{ artist.hotel.from | formatDate }}
          </div>
          <div class="col">
            {{ artist.hotel.to | formatDate }}
          </div>
          <div class="col">
            {{ getHotel.breakfast }}
          </div>
          <div class="col">
            {{ artist.hotel.comments }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import {getArtistPage, getEventHotels} from "@/artists_api";

export default {
  name: 'ArtistAccomodation',
  props: ["artist", "event"],
  data() {
    return {
      hotels: []
    }
  },
  methods: {
    getHotel(hotelId) {
      return this.hotels.find(hotel => hotel.id === hotelId)
    }
  },
  async mounted() {
    this.hotels = await getEventHotels(this.eventId)
  }
}
</script>
