<template>
  <div class="mt-5">
    <h4 class="text-uppercase">Users</h4>
    <div class="d-flex justify-content-between">
      <div class="col-9">
        Add or replace users who can edit and view the data of {{ artist.name }}. Max 3 users.
      </div>
    </div>
    <div class="card p-3 my-2">
      <template v-for="user in artist.users">
        <div v-bind:key="'user1-'+user.id">
          <div class="row">
            <div class="item-title mb-2 col">Name</div>
            <div class="item-title mb-2 col">E-mail</div>
            <div class="item-title col"></div>
          </div>
          <div class="row" v-bind:key="'user2-'+user.id">
            <div class="col-3"><input type="text" class="form-control" :disabled="editingUser!==user.id"
                                      v-model="user.name"/></div>
            <div class="col-3"><input type="text" class="form-control" :disabled="editingUser!==user.id"
                                      v-model="user.e_mail"></div>
            <div v-if="!artist.form_submitted && editingUser!==user.id" class="col-1 d-flex align-items-end">
              <button class="btn btn-primary me-2" @click="toggleEditingUser(user.id)">
                <Icon name="edit" width="15" height="15"/>
              </button>
              <button class="btn btn-primary" @click="removeUser(user.id)">
                <Icon name="trash" width="15" height="15"/>
              </button>
            </div>
            <div v-else-if="!artist.form_submitted" class="col-1 d-flex align-items-end">
              <button class="btn btn-primary me-2" @click="saveEditedUser(user.id)">
                <Icon name="check" width="15" height="15"/>
              </button>
              <button class="btn btn-primary" @click="cancelEditingUser(user.id)">
                <Icon name="close" width="15" height="15"/>
              </button>
            </div>
          </div>
          <div class="row mt-2" v-if="user.secret_hash !== null">
            <div class="col-12"> Link to set password
              <span class="" style="font-size: 14px">https://mistin.eu/set-password?key={{ user.secret_hash }}</span>
            </div>
          </div>
        </div>
      </template>
      <div class="mt-3" v-if="showAddUser">
        <h5>New user</h5>
        <div class="row">
          <div class="item-title mb-2 col">Name</div>
          <div class="item-title mb-2 col">E-mail</div>
          <div class="item-title mb-2 col"></div>
        </div>
        <div class="row">
          <div class="col"><input type="text" class="form-control" v-model="userName"/></div>
          <div class="col"><input class="form-control" type="email" v-model="userEmail"/></div>
          <div class="col">
            <button class="btn btn-primary" @click="addUser()">
              <Icon name="check" width="15" height="15"/>
            </button>
            <button v-if="showAddUser" class="btn btn-primary ms-2" @click="closeAddUser()">
              <Icon name="close" width="15" height="15"/>
            </button>
          </div>
        </div>
      </div>
      <div class="mt-3" v-if="!artist.form_submitted && artist.users.length < 3">
        <button v-if="!showAddUser" class="btn btn-primary mt-2 " @click="showAddUser = true">Add
          user
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/common/Icon.vue";
import {addArtistUser, removeArtistUser, sendPasswordEmail, updateEditedUser} from "@/artists_api";
import store from "@/store";

export default {
  name: "ArtistUsers",
  components: {Icon},
  props: ['artist', 'eventId', 'artistId'],
  data() {
    return {
      editingUser: null,
      editedUserName: '',
      editedUserEmail: '',
      userEmail: '',
      userName: '',
      showAddUser: false,
    }
  },
  methods: {
    async addUser() {
      const added = await addArtistUser(this.eventId, this.artistId, {
        'name': this.userName,
        'e_mail': this.userEmail
      })
      this.userName = ''
      this.userEmail = ''
      this.artist.users.push(added)
      store.addAlert("success", "User added", true)
      this.closeAddUser()
    },
    toggleEditingUser(id) {
      let a = this.artist.users.filter(c => c.id === id)
      let c = a[0]
      if (this.editingUser == null) {
        this.editingUser = id
        this.editedUserName = c.name
        this.editedUserEmail = c.e_mail
      } else {
        this.editingUser = null
      }
    },
    cancelEditingUser(id) {
      let a = this.artist.users.filter(c => c.id === id)
      let c = a[0]
      c.name = this.editedUserName
      c.e_mail = this.editedUserEmail
      this.editingUser = null
    },
    async removeUser(id) {
      await removeArtistUser(this.eventId, this.artistId, id)
      this.artist.users = this.artist.users.filter(c => c.id !== id)
    },
    async saveEditedUser(id) {
      let index = this.artist.users.findIndex(c => c.id === id)
      let c = await updateEditedUser(this.eventId, this.artistId, id, {
        'name': this.artist.users[index].name,
        'e_mail': this.artist.users[index].e_mail
      })

      this.toggleEditingUser(id)
    },
    sendPasswordEmail(userId) {
      sendPasswordEmail(this.eventId, this.artistId, userId).then(() => {
        let u = this.artist.users.find(u => u.id === userId)
        u.password_sent = true
      })
      store.addAlert("success", "E-mail sent", true)
    },
    closeAddUser() {
      this.showAddUser = false
      this.userName = ''
      this.userEmail = ''
      this.userPassword = ''
      this.userPassword2 = ''
    },
  },
}
</script>
