<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <ArtistsMenu :eventId="eventId" active="artist" :event="event" v-if="event">
      <div v-if="event && artist" class="d-flex flex-row mt-3">
        <div class="flex-grow-1">
          <ArtistBreadcrumbs>
            <li class="breadcrumb-item">
              <router-link :to="{name: 'AdvancingForms', params: { id: event.id }}" class="text-disabled text-decoration-none">
                Advancing forms
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{artist.name}}</li>
          </ArtistBreadcrumbs>
        </div>
      </div>
      <div class="card" v-if="artist">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <router-link :to="{name: 'AdvancingForms', query: {active: 'forms'}, params: { eventId: eventId }}">
            <button class="nav-link" id="template-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                    type="button" role="tab" aria-controls="home-tab-pane active" aria-selected="true">Form
            </button>
            </router-link>
          </li>
          <li class="nav-item" role="presentation">
            <router-link :to="{name: 'AdvancingForms', query: {active: 'artists'}, params: { eventId: eventId }}">
            <button class="nav-link active" id="artists-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane"
                    type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Per artist
            </button>
            </router-link>
          </li>
        </ul>
        <div class="card-body" v-if="form">
          <h3>{{ artist.name }}</h3>
          <form v-on:submit.prevent="addArtist">
            <div class="card-body">
              <div class="row" v-if="form_section_types.includes('Performers')">
                <div class="col">
                  <div class="item-title mb-2">
                    Performers limit
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="number" class="mb-2 form-control pe-0" v-model="artist.performers_limit"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="item-title mb-2">
                    Performers deadline
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="date" class="mb-2 form-control pe-0" v-model="artist.performers_deadline"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="form_section_types.includes('Crew')">
                <div class="col">
                  <div class="item-title mb-2">
                    Crew limit
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="number" class="mb-2 form-control pe-0" v-model="artist.crew_limit"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="item-title mb-2">
                    Crew deadline
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="date" class="mb-2 form-control pe-0" v-model="artist.crew_deadline"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="form_section_types.includes('GeneralAdmissionGuests')">
                <div class="col">
                  <div class="item-title mb-2">
                    Guestlist limit
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="number" class="mb-2 form-control pe-0" v-model="artist.ga_guestlist_limit"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="item-title mb-2">
                    Guestlist deadline
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="date" class="mb-2 form-control pe-0" v-model="artist.ga_guestlist_deadline"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <button class="btn btn-primary" @click="saveChanges()">Save changes</button>
        </div>
      </div>
    </ArtistsMenu>
  </div>
</template>
<script>
import Navbar from '@/components/common/Navbar.vue'
import {getAdvancingForm, getEvent, getEventData} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import ArtistsMenu from "@/components/ArtistsMenu";
import {getEventArtis, updateArtist} from "@/artists_api";
import ArtistBreadcrumbs from "@/components/ArtistBreadcrumbs";

export default {
  name: 'AdvancingFormArtist',
  components: {
    Navbar, ArtistsMenu, ArtistBreadcrumbs
  },
  props: ['eventId', 'artistId'],
  data() {
    return {
      artist: null,
      form: null,
      form_section_types: [],
      event: null,
      general_deadline: null,
      active: 'template',
      deadline: null,
      editingText: '',
      editingId: null
    }
  },
  async mounted() {
    this.artist = await getEventArtis(this.eventId, this.artistId)
    this.form = await getAdvancingForm(this.eventId, this.artist.advancing_form_id)
    this.form_section_types = this.form.sections.map(s => s.section_type)
    this.event = await getEventData(this.eventId)
  },
  methods: {
    async saveChanges() {
      await updateArtist(this.eventId, this.artistId, this.artist)
    }
  },
}
</script>
