<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Stages"/>
    <ArtistsMenu :eventId="eventId" :event="event">
      <div v-if="event" class="d-flex flex-row mt-3">
        <div class="flex-grow-1">
          <Breadcrumbs :event="event" currentPageName="Stages"/>
        </div>
      </div>
      <h2>Stages</h2>

      <div class="card elevation-16 mb-3 col-md-6">
        <div class="card-body">
          <button v-if="!showAddStage" class="btn btn-primary" @click="showAddStage = true">Add stage</button>

          <div v-if="showAddStage" class="card p-2">
            <div class="d-flex">
              <span class="flex-grow-1">Add a stage</span>
              <button @click="closeAddStage" class="btn btn-secondary">Close</button>
            </div>
            <form v-on:submit.prevent="createStage">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <div class="item-title mb-2">
                      Name
                    </div>
                    <div class="row">
                      <div class="col-auto">
                        <input required type="text" class="mb-2 form-control pe-0" v-model="name"/>
                      </div>
                    </div>
                  </div>
                </div>
                <button class="mt-2 btn btn-primary">Add stage</button>
              </div>
            </form>
          </div>
          <table v-if="stages.length > 0" class="mt-2 table table-bordered">
            <thead>
            <tr>
              <th>Name</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="stage in stages" v-bind:key="stage.id">
              <td>{{stage.name}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ArtistsMenu>
  </div>
</template>
<script>
import Navbar from '@/components/common/Navbar.vue'
import {addStage, getEventArtists, getStages} from "@/artists_api";
import {getEvent, getEventData} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import ArtistsMenu from "@/components/ArtistsMenu";

export default {
  name: 'Artists',
  components: {
    Navbar, Breadcrumbs, ArtistsMenu

  },
  props: ['eventId'],
  data() {
    return {
      artists: [],
      stages: [],
      event: null,
      showAddStage: false,
      name: '',
    }
  },
  methods: {
    closeAddStage() {
      this.name = ''
    },
  },
  async mounted() {
    this.stages = await getStages(this.eventId)
    this.event = await getEventData(this.eventId)
  }
}
</script>
