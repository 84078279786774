<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <ArtistsMenu :eventId="eventId" active="accomodation" :event="event" v-if="event">
      <div v-if="event" class="d-flex flex-row mt-3">
        <div class="flex-grow-1">
          <Breadcrumbs :event="event" currentPageName="Accomodation"/>
        </div>
      </div>

      <h1>Edit hotel</h1>
      <HotelDetails :hotel="hotel" :onSave="updateHotel" />
    </ArtistsMenu>
  </div>
</template>

<script>
import ArtistsMenu from "@/components/ArtistsMenu";
import {addHotel, getEventHotel, getEventHotels, updateHotel} from "@/artists_api";
import store from "@/store";
import {getEventData} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import Navbar from "@/components/common/Navbar.vue";
import {VueEditor} from "vue2-editor";
import HotelDetails from "@/components/HotelDetails.vue";


export default {
  name: "EditHotel",
  components: {
    HotelDetails,
    ArtistsMenu, Breadcrumbs, Navbar
  },
  props: ['eventId', 'hotelId'],
  data() {
    return {
      hotel: {
        check_out: '',
        check_in: '',
        name: '',
        address: '',
        breakfast: '',
        additional_information: '',
      },
      event: null,
    }
  },
  async mounted() {
    this.event = await getEventData(this.eventId)
    this.hotel = await getEventHotel(this.eventId, this.hotelId)

  },
  methods: {
    updateHotel() {
      let hotel = {name: this.hotel.name, address: this.hotel.address,
        breakfast: this.hotel.breakfast, check_in: this.hotel.check_in, check_out: this.hotel.check_out,
        additional_information: this.hotel.additional_information}
      updateHotel(this.eventId, this.hotelId, hotel).then(() =>
      {
        this.$router.replace({name: 'ArtistsAccomodation', params: {eventId: this.eventId}})
        store.addAlert('success', 'Hotel saved', true)
      })
    }
  },
}
</script>
