<template>
  <div>
    ASASSAA
    <h2 id="schedule" class="mt-5">Schedule</h2>
    <div v-if="schedules.length === 0" class="d-flex justify-content-center">
      No schedules created yet
    </div>
    <div v-else>
      <div v-for="schedule in schedules" v-bind:key="'schedule-'+schedule.id">
        <div class="mt-3 row">
          <h4>{{ schedule.name }}</h4>
          <div class="row mb-3">
          </div>
          <template v-for="stage in stages">
            <div v-bind:key="'stage-'+stage.id" class="col-md-4 mt-2 mb-5">
              <strong class="mb-5">{{ stage.name }}</strong>
              <br/>
              <template v-for="a in schedule.artists">
                <div v-if="a.stage_id === stage.id" v-bind:key="'a-' + a.id"
                     :class="{'text-danger': a.artist_id === artistId, 'fw-bolder': a.artist_id === artistId}">
                  {{ a.start_time }}&nbsp;&#8212;&nbsp;{{ a.end_time }} {{ getArtist(a.artist_id).name }}
                </div>
              </template>
            </div>
          </template>
        </div>
        <hr/>
      </div>
    </div>
  </div>
</template>
<script>
import {getEventArtists, getEventSchedules, getStages} from "@/artists_api";

export default {
  name: 'ArtistSchedule',
  props: ["artistId", "eventId"],
  data() {
    return {
      stages: [],
      schedules: [],
      artists: []
    }
  },
  methods: {
    getArtist(artistId) {
      return this.artists.filter(a => a.id === artistId)[0]
    }
  },
  async mounted() {
    this.artists = await getEventArtists(this.eventId)
    this.schedules = await getEventSchedules(this.eventId)
    this.stages = await getStages(this.eventId)
  }
}
</script>
