<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="CheckIn"/>
    <div id="" class="container-fluid check-in">
      <div v-if="event" class="row">
        <div id="sidebarMenu" class="border-end border-primary col-md-3 col-lg-2 d-md-block sidebar collapse">
          <h5 class="mt-3">Overview</h5>
          <p class="subtitle2">Total: {{ totalPeople }}<br/>
            Checked in: {{ totalPeopleCheckedIn }}<br/>
            Not checked in: {{ totalPeople - totalPeopleCheckedIn }}</p>

          <div v-if="selectedUnit" class="subtitle2">
            <h5>In this unit</h5>
            <p>
              Total: {{ selectedUnitTotal }}<br/>
              Checked in: {{ selectedUnitCheckedIn }}<br/>
              Not checked in: {{ selectedUnitTotal - selectedUnitCheckedIn }}
            </p>
          </div>
          <div v-if="!isOnlyGuestlist">
            <FilterUnitGroup :onlyGuestlist="isOnlyGuestlist" v-on:selectedUnit="selectUnit"
                             v-on:selectedGroup="selectGroup" :units="units"/>
          </div>
        </div>
        <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <div class="d-flex flex-row mt-3">
            <div class="flex-grow-1">
              <Breadcrumbs :event="event" currentPageName="Check in"/>
            </div>
          </div>
          <div>
            <h2>Check in</h2>
          </div>
          <div v-if="event !== null">
            <div class="d-flex flex-row bd-highlight mb-3">
              <div class="p-2 flex-grow-1">
                <input type="text" v-model="textFilter" placeholder="Search"/>
                <button class="ms-1 btn btn-primary" @click="clearSearch">
                  <Icon name="close" width="15" height="15"/>
                </button>
              </div>
              <div class="p-2">
                <button @click="toggleCheckedIn(false)"
                        v-bind:class="[checkedInFilter === false ? 'btn-primary' : 'btn-outline-primary', 'btn', 'btn-sm', 'me-3']">
                  Not checked in
                </button>
                <button @click="toggleCheckedIn(true)"
                        v-bind:class="[checkedInFilter === true ? 'btn-primary' : 'btn-outline-primary', 'btn', 'btn-sm', 'me-3 py-2 px-3']">
                  Checked in
                </button>
                <button type="button" @click="toggleCheckedIn(null)"
                        v-bind:class="[checkedInFilter === null ? 'btn-primary' : 'btn-outline-primary', 'btn btn-sm']">
                  All
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <PersonCheckInItem v-for="person in selectedPersons" v-bind:key="person.id"
                                   :person="person" :unit_names="unit_names" :group_names="group_names"
                                   :wristbands="wristbands" :badges="badges"
                                   :eventId="eventId" v-on:opened="openedItem" :openedItemId="openedPerson"
                                   :onlyGuestlist="isOnlyGuestlist"
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/common/Navbar.vue";
import PersonCheckInItem from "@/components/PersonCheckInItem";
import {checkInPerson, getEvent, getEventPeople} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import FilterUnitGroup from "@/components/FilterUnitGroup";
import store from "@/store";
import Icon from "@/components/common/Icon";

export default {
  name: "CheckIn",
  components: {Icon, FilterUnitGroup, Breadcrumbs, Navbar, PersonCheckInItem},
  props: ['eventId'],
  data() {
    return {
      event: null,
      people: [],
      fields: [],
      units: [],
      unit_names: [],
      group_names: [],
      selectedPerson: null,
      selectedGroup: null,
      selectedUnit: null,
      wristbands: [],
      badges: [],
      textFilter: "",
      checkedInFilter: false,
      openedPerson: null,
      isOnlyGuestlist: false
    }
  },
  async mounted() {
    const people = await getEventPeople(this.eventId)
    this.people = people.people
    this.fields = people.fields

    const result = await getEvent(this.eventId)
    this.units = result.units
    this.group_names = result.group_names
    this.unit_names = result.unit_names
    this.wristbands = result.wristbands
    this.badges = result.badges

    this.event = result.event
    this.isOnlyGuestlist = store.isOnlyGuestlist()
    setInterval(this.retryCheckin, 30 * 1000)

    let source = new EventSource(`/api/stream?channel=event-${this.eventId}`);
    source.addEventListener('checked-in', (event) => {
      var data = JSON.parse(event.data);
      console.log(event.data)
      this.updatePersonFromEvent(data.person_id, data.checked_in_count)
    }, false);
    source.addEventListener('removed-check-in', (event) => {
      var data = JSON.parse(event.data);
      console.log(event.data)
      this.updatePersonFromEvent(data.person_id, data.checked_in_count)
    }, false);

    source.addEventListener('person-added', function(event) {
      var data = JSON.parse(event.data);
      console.log("The server says " + data);
    }, false);
    source.addEventListener('person-updated', function(event) {
      var data = JSON.parse(event.data);
      console.log("The server says " + data);
    }, false);
    source.addEventListener('person-deleted', function(event) {
      var data = JSON.parse(event.data);
      console.log("The server says " + data);
    }, false);

    source.addEventListener('error', function(event) {
      console.log("Viga?", event)
    }, false);
  },
  methods: {
    updatePersonFromEvent(person_id, checked_in_count) {
      let person = this.people.find(p => p.id == person_id)
      person.checked_in_count = checked_in_count
    },
    async retryCheckin() {
      let retrying = store.getCheckinRetries()
      let newRetries = []
      for (let checkin of retrying) {
        try {
          console.log("Retrying:", checkin.body)
          let result = await checkInPerson(this.eventId, checkin.body)
          if (result.ok) {
            let person = this.people.find(p => p.id === checkin.body.person_id)
            store.addAlert("success", `Checked in ${person.name}`)
            console.log(`Removing ${checkin.body.person_id} from queue`)
          }
        } catch (error) {
          console.log(error)
          newRetries.push(checkin)
          console.log("Adding back to retry queue", checkin, "new retries: ", newRetries)
        }
      }
      console.log("New checkin retries", newRetries)
      store.setCheckinRetries(newRetries)
    },
    selectGroup(group) {
      console.log(group)
      if (this.selectedGroup === group) {
        this.selectedGroup = null
      } else {
        this.selectedGroup = group
      }
    },
    selectUnit(unit) {
      console.log(unit)
      if (this.selectedUnit === unit) {
        this.selectedUnit = null
      } else {
        this.selectedUnit = unit
        this.selectedGroup = null
      }
    },
    selectAll() {
      this.selectedGroup = null
      this.selectedUnit = null
    },
    filterText(p) {
      // Kommentaari järgi otsing ajutiselt väljas
//      if (p.comments && p.comments.toLowerCase().includes(this.textFilter.toLowerCase())) {
//        return true
//      }

      if (p.name.toLowerCase().includes(this.textFilter.toLowerCase())) {
        return true
      } else {
        return false
      }

      return false
    },
    filterCheckedIn(p) {
      if (this.checkedInFilter === null)
        return true
      if (this.checkedInFilter === false && (p.guest_count !== p.checked_in_count))
        return true
      else if (this.checkedInFilter === true && p.checked_in_count === p.guest_count) {
        return true
      }
      return false
    },
    filterPersonGroupUnit(p) {
      if (this.selectedUnit === null && this.selectedGroup === null) {
        return true
      } else {
        if (p.unit_id !== null && p.unit_id === this.selectedUnit && this.selectedGroup === null)
          return true
        else if (this.selectedGroup !== null && p.group_id === this.selectedGroup) {
          return true
        }
      }
    },
    toggleCheckedIn(value) {
      this.checkedInFilter = value
    },
    openedItem: function (personId) {
      if (personId === this.openedPerson) {
        this.openedPerson = null
      } else {
        this.openedPerson = personId
      }
    },
    clearSearch() {
      this.textFilter = ''
    }
  },
  computed: {
    selectedPersons: function () {
      return this.people.filter(p => {
        return this.filterPersonGroupUnit(p) && this.filterText(p) && this.filterCheckedIn(p)
      })
    },
    selectedUnitPeople: function () {
      return this.people.filter(p => {
        return this.selectedUnit != null && p.unit_id === this.selectedUnit
      })
    },
    totalPeople: function () {
      return this.people.map(p => p.guest_count).reduce((total, p) => total + p, 0)
    },
    totalPeopleCheckedIn: function () {
      return this.people.map(p => p.checked_in_count).reduce((total, p) => total + p, 0)
    },
    selectedUnitTotal: function () {
      return this.selectedUnitPeople.map(p => p.guest_count).reduce((total, p) => total + p, 0)
    },
    selectedUnitCheckedIn: function () {
      return this.selectedUnitPeople.map(p => p.checked_in_count).reduce((total, p) => total + p, 0)
    }
  },
}
</script>

