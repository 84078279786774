<template>
  <div>
    <Navbar :event="event" :eventId="id" currentPage="Event"></Navbar>
    <div id="" class="container-fluid">
      <div v-if="event">
        <div class="row">
          <div class="col-12">
            <h1>{{ event.name }}</h1>
            <div v-if="guest_limit && guest_limit.limit">
              {{ guest_limit.guest_count || '0' }} of {{ guest_limit.limit }} guests
            </div>
            <table class="table w-50">
              <thead>
              <tr>
                <th>Unit</th>
                <th>Group</th>
                <th>Total</th>
                <th>Checked in</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Total:</td>
                <td></td>
                <td>{{ totals['guests'] }}</td>
                <td>{{ totals['checked_in'] }}</td>
              </tr>
              <template v-for="(s, index) in stats">
                <tr v-bind:key="index">
                  <td>{{ s.unit_name }}</td>
                  <td></td>
                  <td>{{ s.guest_count }}</td>
                  <td>{{ s.checked_in_count }}</td>
                </tr>
                <tr v-for="group in s.groups" v-bind:key="group.id + '-group'">
                  <td></td>
                  <td>{{ group.name }}</td>
                  <td>{{ group.guest_count }}</td>
                  <td>{{ group.checked_in_count }}</td>
                </tr>
              </template>
              </tbody>
            </table>

            <h4 class="mt-3">Wristbands</h4>
            <table class="table w-50">
              <thead>
              <tr>
                <th>Name</th>
                <th>Count</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="wristband in wristbands" v-bind:key="'wr-' + wristband.name">
                <td>{{ wristband.name }}</td>
                <td>{{ wristband.count }}</td>
              </tr>
              </tbody>
            </table>
            <h4 class="mt-3">Badges</h4>
            <table class="table w-50">
              <thead>
              <tr>
                <th>Name</th>
                <th>Count</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="badge in badges" v-bind:key="'wr-' + badge.name">
                <td>{{ badge.name }}</td>
                <td>{{ badge.count }}</td>
              </tr>
              </tbody>
            </table>
            <div v-if="event.id === 34">
              <h4>Käepaelad</h4>
              <table class="table w-50">
                <tr>
                  <td>VIP</td>
                  <td>{{ comments['vip'] }}</td>
                </tr>
                <tr>
                  <td>ULTRAVIP</td>
                  <td>{{ comments['ultravip'] }}</td>
                </tr>
                <tr>
                  <td>AMMENDE</td>
                  <td>{{ comments['ammende'] }}</td>
                </tr>
                <tr>
                  <td>PRIORITY</td>
                  <td>{{ comments['priority'] }}</td>
                </tr>
              </table>
            </div>
            <ActivityLogs :logs="logs"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from '@/components/common/Navbar.vue'
import {getEvent, getEventCheckInStats, getEventLogs} from "@/api";
import ActivityLogs from "@/components/ActivityLogs";

export default {
  name: 'Event',
  props: ['id'],
  components: {
    ActivityLogs,
    Navbar,
  },
  data() {
    return {
      event: null,
      logs: [],
      stats: [],
      totals: null,
      guest_limit: null,
      wristbands: [],
      badges: [],
      comments: {}
    }
  },
  async mounted() {
    const result = await getEvent(this.id)
    this.event = result.event
    this.guest_limit = result.guest_limit
    await getEventCheckInStats(this.id).then(result => {
      this.stats = result.units
      this.totals = result.totals
      this.wristbands = result.wristbands
      this.badges = result.badges
      this.comments = result.comments
    })
    this.logs = await getEventLogs(this.id)
  }
}
</script>
