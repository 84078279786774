<template>
  <div>
    <ArtistNavbar currentPage="Artists"/>

    <ArtistPageMenu v-if="event" :event="event" currentPage="Accomodation" :artist="artist">
      <ArtistAccomodation :artist="artist" :event="event" />
    </ArtistPageMenu>
  </div>
</template>
<script>
import ArtistNavbar from '@/components/ArtistNavbar.vue'
import ArtistPageMenu from "@/components/ArtistPageMenu";
import store from "@/store";
import {getArtistPage, getEventHotels} from "@/artists_api";
import ArtistAccomodation from "@/views/artist/ArtistAccomodation";

export default {
  name: 'ArtistPageAccomodation',
  components: {ArtistAccomodation, ArtistPageMenu, ArtistNavbar},
  props: [],
  data() {
    return {
      artist: null,
      event: null,
      form: null,
    }
  },
  async mounted() {
    this.artistId = store.getArtist()
    await getArtistPage(this.artistId).then(result => {
      this.artist = result.artist
      this.event = result.event
      this.eventId = this.event.id
      this.form = result.artist.form
    })
    this.hotels = await getEventHotels(this.eventId)
  }
}
</script>
