<template>
  <div class="card elevation-16 mb-3">
    <div class="card-body">
      <form v-on:submit.prevent="onSave()">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="item-title mb-2">
                Name
              </div>
              <div class="row">
                <div class="col-md-8">
                  <input type="text" class="mb-2 form-control pe-0" v-model="hotel.name" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="item-title mb-2">
                Address
              </div>
              <div class="row">
                <div class="col-md-8">
                  <input  type="text" class="mb-2 form-control pe-0" v-model="hotel.address" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="item-title mb-2">
                Check in
              </div>
              <div class="row">
                <div class="col-md-8">
                  <input type="text" class="mb-2 form-control pe-0" v-model="hotel.check_in" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="item-title mb-2">
                Check out
              </div>
              <div class="row">
                <div class="col-md-8">
                  <input type="text" class="mb-2 form-control pe-0" v-model="hotel.check_out" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="item-title mb-2">
                Breakfast
              </div>
              <div class="row">
                <div class="col-md-8">
                  <input type="text" class="mb-2 form-control pe-0" v-model="hotel.breakfast" />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3 row">
            <div class="col-12">
              <div class="item-title mb-2">
                Additional information
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <vue-editor
                    id="editor"
                    :editor-toolbar="editorOptions"
                    v-model="hotel.additional_information"
                  ></vue-editor>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <input type="submit" class="btn btn-primary" value="Save"/>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {VueEditor} from "vue2-editor";

export default {
  name: "HotelDetails",
  data() {
    return {
      editorOptions: [
        [{ "font": [] }, { "size": ["small", false, "large", "huge"] }], // custom dropdown
        ["bold", "italic", "underline", "strike"],
        [{ "color": [] }, { "background": [] }],
        [{ "script": "sub" }, { "script": "super" }],
        [{ "header": 1 }, { "header": 2 }, "blockquote", "code-block"],
        [{ "list": "ordered" }, { "list": "bullet" }, { "indent": "-1" }, { "indent": "+1" }],
        [{ "direction": "rtl" }, { "align": [] }],
        ["link"],
        ["clean"]
      ]
    }
  },
  components: {
    VueEditor
  },
  props: ['hotel', 'onSave']
}
</script>
