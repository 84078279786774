<template>
  <div>
    <Navbar/>
    <AccountAdminMenu>
      <div>
        <h1>Payment plan</h1>

        <div v-if="paymentPlan.plan_name === 'Free'">
          <h3>Free</h3>
          Users: 1/1 <br/>
          Upgrade to access more features
        </div>
        <div v-if="paymentPlan.plan_name === 'Unlimited'">
          <h3>Unlimited plan</h3>
        </div>
        <div v-if="paymentPlan.plan_name === 'One event'">
          <h3>One event</h3>
          Users: {{ paymentPlan.user_count }}/{{ paymentPlan.user_limit }}
        </div>
        <div v-if="paymentPlan.plan_name ==='Monthly 1' || paymentPlan.plan_name==='Monthly 2'">
          <h3>{{ paymentPlan.plan_name }}</h3>
          Events: {{ paymentPlan.event_count }} / {{ paymentPlan.event_limit }}<br/>
          Users: {{ paymentPlan.user_count }} / {{ paymentPlan.user_limit }}
        </div>
        <div v-if="paymentPlan.plan_name==='10 events'">
          <h3>{{ paymentPlan.plan_name }}</h3>
          Events: {{ paymentPlan.event_count }} / {{ paymentPlan.event_limit}}<br/>
          Users: {{ paymentPlan.user_count }} / {{ paymentPlan.user_limit }}
        </div>
        <div class="d-flex mt-3">
          <div v-if="paymentPlan.plan_name !== 'Free' && paymentPlan.plan_name !== 'Unlimited'">
            <div v-if="paymentPlan.plan_expires">
              Plan expires: {{ paymentPlan.plan_expires }}
            </div>
            <button @click="cancelPlan()" class="btn btn-primary">Cancel plan</button>
          </div>
          <button class="ms-3 btn btn-primary" @click="changePlan()">Change plan</button>
        </div>
      </div>
    </AccountAdminMenu>
  </div>
</template>

<script>
import Navbar from "@/components/common/Navbar.vue";
import Icon from "@/components/common/Icon";
import AccountAdminMenu from "@/components/AccountAdminMenu";
import {getCurrentPlan} from "@/api";
import router from "@/router";

export default {
  name: "AccountPaymentPlan",
  components: {
    Navbar, AccountAdminMenu
  },
  data() {
    return {
      paymentPlan: null
    }
  },
  methods: {
    cancelPlan() {
      console.log("cancel plan (from next payment, whatever), select free plan")
    },
    changePlan() {
      router.push('/account-payment-plan-select')
    }
  },
  async mounted() {
    getCurrentPlan().then(result => this.paymentPlan = result)
  }
}
</script>

<style scoped>

</style>
