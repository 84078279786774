<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <ArtistsMenu :eventId="eventId" active="activity_log" :event="event" v-if="event">
      <div v-if="event" class="d-flex flex-row mt-3">
        <div class="flex-grow-1">
          <Breadcrumbs :event="event" currentPageName="Activity log"/>
        </div>
      </div>
      <h2 class="mb-5">Artists activity log</h2>

      <ActivityLogs :logs="logs" />
    </ArtistsMenu>
  </div>
</template>
<script>
import Navbar from '@/components/common/Navbar.vue'
import {getEvenArtistsLogs, getEventData} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import ArtistsMenu from "@/components/ArtistsMenu";
import {getEventArtists, getEventGeneralInformation, saveEventGeneralInformation} from "@/artists_api";
import ActivityLogs from "@/components/ActivityLogs";

export default {
  name: 'ArtistsActivityLog',
  components: {
    ActivityLogs,
    ArtistsMenu,
    Navbar,
    Breadcrumbs
  },
  props: ['eventId'],
  data() {
    return {
      event: null,
      logs: []
    }
  },
  async mounted() {
    this.event = await getEventData(this.eventId)
    this.logs = await getEvenArtistsLogs(this.eventId)
  }
}
</script>
