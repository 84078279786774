<template>
  <div>
    <Navbar/>
    <AccountAdminMenu>
      <div>
        <h1>Account settings</h1>
        <h3>
          Change your password
        </h3>
        <ChangePassword />

      </div>
    </AccountAdminMenu>
  </div>
</template>

<script>
import Navbar from "@/components/common/Navbar.vue";
import Icon from "@/components/common/Icon";
import AccountAdminMenu from "@/components/AccountAdminMenu";
import {changePassword} from "@/api";
import ChangePassword from "@/components/ChangePassword";

export default {
  name: "AccountPaymentPlanSelect",
  components: {
    ChangePassword,
    Navbar, AccountAdminMenu,
  },
  data() {
    return {
      plans: [],
      users: [],
      plan: {},
      current_password: '',
      new_password: '',
      new_password2: '',
      passwords_dont_match: false,
      message: null
    }
  },
  async mounted() {
  },
  methods: {
    async changePassword() {
      if (this.new_password === this.new_password2) {
        let result = await changePassword(this.current_password, this.new_password)
        this.passwords_dont_match = false
        if (result['ok']) {
          this.password_changed = true
          this.message = result['message']
        } else {
          this.message = result['message']
        }
      } else {
        this.passwords_dont_match = true
        this.message = null
      }
    }
  },
}
</script>
