<template>
  <div>
    <Navbar currentPage="Events"/>
    <div class="container-fluid">

      <div class="row mt-3">
        <div class="card col-5 mx-auto">
          <div class="card-body">
            <h3 class="card-title">My events</h3>
            <button v-if="canAddEvent" type="button" class="btn btn-primary">
              <router-link :to="{name: 'AddEvent' }" class="nav-top-link text-underline-none text-light">
                <span>Add event</span>
              </router-link>
            </button>
            <EventItem v-for="event in events" :event="event" v-bind:key="event.id"/>

            <div v-if="event_users.length > 0">
              <div class="card mt-3" v-for="event of event_users" v-bind:key="event.id">
                <div class="card-body">
                  <div class="row">
                    <span class="col-7">
                      <router-link
                        :to="{name: 'CheckIn', params: { eventId: event.event_id }}"><strong>{{ event.event_name }}</strong></router-link></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="event_users.length === 0" class="card col-5 mx-auto">
          <div class="card-body">
            <h3 class="card-title">My account</h3>
            Payment plan:
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import store from '../store'
import Navbar from '@/components/common/Navbar.vue'
import EventItem from '@/components/EventItem.vue'
import {getEvents} from "@/api";

export default {
  name: 'Events',
  components: {
    Navbar,
    EventItem
  },
  data() {
    return {
      events: [],
      event_users: [],
      canAddEvent: false
    }
  },
  async mounted() {
    await getEvents().then(result => this.events = result)
    this.event_users = store.getEventUsers()
    const roles = store.getRoles()
    this.canAddEvent = roles.length > 0 && (roles.includes('owner') || roles.includes('manager'))
  }
}
</script>
