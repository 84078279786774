<template>
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card shadow-2-strong" style="border-radius: 1rem;">
          <div class="card-body p-5 text-center">
            <form v-on:submit.prevent="login">
              <h3 class="mb-5">Log in</h3>
              <div class="form-outline mb-4">
                <label class="form-label" for="email">Email or username</label>
                <input type="text" id="email" v-model="email" class="form-control form-control-lg"/>
              </div>
              <div class="form-outline mb-4">
                <label class="form-label" for="password">Password</label>
                <input type="password" id="password" v-model="password" class="form-control form-control-lg"/>
              </div>
              <div v-if="errors" class="form-outline mb-4 errors">
                The e-mail/username or password is incorrect
              </div>
              <button class="btn btn-primary btn-block" type="submit">Login</button>
            </form>
            <p class="mt-5">
              Don't have an account?
            </p>
            <p>
              <router-link :to="{name: 'Register' }" class="">
                Register here
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import store from '../store'
import {login} from "@/api";

export default {
  name: 'Login',
  components: {},
  data() {
    return {
      errors: false,
      email: '',
      password: ''
    }
  },
  methods: {
    async login() {
      const t = this
      await axios.post('/api/auth', {username: this.email, password: this.password})
        .then(result => {

          store.saveJwt(result.data.access_token)

          let artists = store.getArtists()

          if (artists) {
            if (artists.length === 1) {
              this.$router.replace({name: 'ArtistAdvancingForm'})
            } else {
              this.$router.replace({name: 'ArtistSelectArtist'})
            }
            return
          }

          let event_users = store.getEventUsers()
          if (event_users.length === 1) {
            if (event_users[0].role === 'worker_bee') {
              this.$router.replace({name: 'CheckIn', params: {eventId: event_users[0].event_id}})
              return
            } else if (event_users[0].role === 'security') {
              this.$router.replace({name: 'AccessSystemView', params: {eventId: event_users[0].event_id}})
              return
            }
          }

          this.$router.replace({name: 'Events'})
        })
        .catch(function (error) {
            t.errors = true
          }
        )
    }
  }
}
</script>
